import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { useSearchParams } from "react-router-dom";
import { useGlobalStore } from "~/store";
import useRepository from "~/common/hooks/useRepository";
import { HR_GET_WORK_TYPE } from "~/store/organization/repository";
import MemberDetailModal from "~/pages/hr/TeamInfoPage/modal/MemberDetailModal";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { clearOrgData } from "~/store/organization/actions";
import LoadingPage from "~/pages/LoadingPage";
import { PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import View from "./View";
import Empty from "./Empty";
import { getFlatChart } from "~/common/components/TreeView/utils";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { HR_GET_ORG } from "~/store/organization/repository";

const OrganizationPage = () => {
	const [
		{
			user: { company },
			organization: { isTreeEmpty, treeData, orgMemberData },
		},
		dispatch,
	] = useGlobalStore();

	const [getWorkTypeList] = useRepository(HR_GET_WORK_TYPE);
	const [getOrgData] = useRepository(HR_GET_ORG);

	const [selectedMember, setSelectedMember] = useState(null);
	const [memberDetailModal, setMemberDetailModal] = useState(false);
	const [apiState, setApiState] = useState("loading");
	const [flatData, setFlatData] = useState([]);
	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const [searchParams, setSearchParams] = useSearchParams();

	const idx = searchParams.get("idx");

	const generateData = () => {
		const flatCompany = {
			id: "company",
			img: company.logo,
			name: company.name,
			tags: ["company"],
		};
		const flatOrg = getFlatChart(treeData);

		const flatUsers = orgMemberData.map((d) => ({
			...d,
			id: d.idx,
			stpid: d.orgId,
			img: d.img ?? defaultAvatar,
			name: d.name ?? "",
		}));

		setFlatData([flatCompany, ...flatOrg, ...flatUsers]);
	};

	useEffect(() => {
		dispatch(clearOrgData());
		getWorkTypeList();
	}, []);

	useEffect(() => {
		getOrgData({ usage: "org" }).then(() => setApiState("done"));
	}, []);

	useEffect(() => {
		if (apiState !== "done") return;
		if (isTreeEmpty === true) {
			setPage(PAGE_STATE.EMPTY);
		} else if (isTreeEmpty === false && orgMemberData.length !== 0) {
			generateData();
			setPage(PAGE_STATE.VIEW);
		}
	}, [apiState]);

	useEffect(() => {
		if (idx) {
			getWorkTypeList().then(() => {
				setSelectedMember(parseInt(idx));
				setMemberDetailModal(true);
				setSearchParams({});
			});
		}
	}, []);

	return (
		<>
			<NavbarAndSidebarLayout menu={["hr", "organization"]} type="main">
				<If condition={page}>
					<Case condition={PAGE_STATE.EMPTY}>
						<PageHeader />
						<Empty />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<PageHeader />
						<View
							flatData={flatData}
							setModalToggle={setMemberDetailModal}
							setSelectedMember={setSelectedMember}
						/>
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<LoadingPage />
					</Case>
				</If>
			</NavbarAndSidebarLayout>
			<ModalTemplate
				title="팀원정보"
				modalState={memberDetailModal}
				setModalToggle={setMemberDetailModal}
				width={800}
				className="teamInfo-modal"
				closeable={false}
			>
				<MemberDetailModal
					{...{
						selectedMember,
					}}
				/>
			</ModalTemplate>
		</>
	);
};

export default OrganizationPage;
