import React from "react";

const Text = ({ form }) => {

	const colorMap = {
		red: "#ff3c00",
		blue: "#00a4ff",
		green: "#71bb30",
		yellow: "#ffd800",
	};

	const color = colorMap[form.color] || null;

	return (
		<>
			<p className="certified-phrase" style={{color : color }}>
				{form.title}
			</p>
		</>
	);
};

export default Text;
