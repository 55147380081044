import React from "react";
import { useTranslation } from "react-i18next";
import { FiveStar } from "~/assets/svg/LandingIcon";
import testimonial1 from "~/assets/img/persona/landingPage/testimonials1.png";
import testimonial2 from "~/assets/img/persona/landingPage/testimonials2.png";
import testimonial3 from "~/assets/img/persona/landingPage/testimonials3.png";

export default function Testimonials() {
	const { t } = useTranslation();
	const testimonialItems = [
		{
			name: t("Landing.Section.testimonials.testimonial.1.name"),
			position: t("Landing.Section.testimonials.testimonial.1.position"),
			image: testimonial1,
			testimonial: t("Landing.Section.testimonials.testimonial.1.text"),
		},
		{
			name: t("Landing.Section.testimonials.testimonial.2.name"),
			position: t("Landing.Section.testimonials.testimonial.2.position"),
			image: testimonial2,
			testimonial: t("Landing.Section.testimonials.testimonial.2.text"),
		},
		{
			name: t("Landing.Section.testimonials.testimonial.3.name"),
			position: t("Landing.Section.testimonials.testimonial.3.position"),
			image: testimonial3,
			testimonial: t("Landing.Section.testimonials.testimonial.3.text"),
		},
	];
	return (
		<section className="mx-auto max-w-[1920px]">
			<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%]">
				<h1 className="text-green-950 text-2xl pt-24 pb-8 text-center ">
					{t("Landing.Section.testimonials.heading")}
				</h1>
				<span className="flex justify-center pb-16">
					{t("Landing.Section.testimonials.sub-heading")}
				</span>
				<div className="md:flex block gap-16 items-end pb-16">
					{testimonialItems.map((item, index) => (
						<div
							key={index}
							className="bg-[#DEEDF0] p-6 rounded-tl-2xl rounded-br-2xl md:w-[30%] w-full md:mb-0 mb-4"
						>
							<div>{item.testimonial}</div>
							<div className="2xl:flex block justify-between items-end">
								<div className="flex gap-2 mt-8">
									<img src={item.image} className="h-[40px] w-[40px]" />
									<div>
										<div>{item.name}</div>
										<div>{item.position}</div>
									</div>
								</div>
								<div className="2xl:mt-0 mt-4">
									<FiveStar />
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}
