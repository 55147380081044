import React, { useEffect, useRef, useState } from "react";

// Assets
import logo from "~/assets/img/logo.svg";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import { logout } from "~/store/auth/actions";
import useAlert from "~/common/hooks/useAlert";
// import TransLanguageSelect from "~/common/components/TransLanguageSelect";
import { useTranslation } from "react-i18next";
import { HeaderAlarmBellIcon, HeaderPluginIcon, TaxInvoice } from "~/assets/svg/HeaderIcon";
import { ProfileIcon } from "~/assets/svg/InterfacesIcon";
import useRepository from "~/common/hooks/useRepository";
import { ALARM_LIST } from "~/store/alarm/repository";

const TopNav = () => {
	const { t } = useTranslation();
	const userDropDownRef = useRef(null);
	const [
		{
			user: { info },
			alarm: { alarmList },
			payment: { isFailedPayment },
		},
		dispatch,
	] = useGlobalStore();
	const unreadAlarmCount = alarmList.filter((alarm) => !alarm.read).length;

	const navigate = useNavigate();
	const [toggleUserDropdown, setToggleUserDropdown] = useState(false);
	const [showAlert, Alert] = useAlert();
	const [getAlarmList] = useRepository(ALARM_LIST);

	function handleUserClickOutSide(e) {
		if (userDropDownRef.current && !userDropDownRef.current.contains(e.target)) {
			setToggleUserDropdown(false);
		}
	}

	useEffect(() => {
		if (toggleUserDropdown) {
			document.addEventListener("click", handleUserClickOutSide, false);
			return () => {
				document.addEventListener("click", handleUserClickOutSide, true);
			};
		}
	});

	const handleLogout = () => {
		dispatch(logout());
		navigate("/");
	};

	useEffect(() => {
		getAlarmList();
	}, []);

	return (
		<nav className="l__nav">
			<div className="nav-left">
				<button className="btn-menuResize" />
				<button className="btn__neumorphic" color="primary" onClick={showAlert}>
					<TaxInvoice />
					{t("components.TopNav.btn-announce")}
				</button>
			</div>
			<div className="logo-wrap">
				<h1 className="logo" onClick={() => navigate("/dashboard")}>
					<img src={logo} alt="로고" />
				</h1>
			</div>
			<div className="nav-right">
				{/* <ul className={`neumorphic-list ${info.role === "admin" ? "add-admin" : ""}`}> */}
				<ul className={`neumorphic-list add-admin`}>
					<li className="neumorphic-item" onClick={() => navigate("/management/alarm/list")}>
						<HeaderAlarmBellIcon />
						{unreadAlarmCount ? (
							<span className="badge">{unreadAlarmCount}</span>
						) : (
							<></>
						)}
					</li>
				</ul>
				<div className="user-wrap" ref={userDropDownRef}>
					<div
						className="user"
						onClick={() => setToggleUserDropdown(!toggleUserDropdown)}
					>
						<div className="img-wrap">
							{info.img ? (
								<img
									src={info.img ?? defaultAvatar}
									onError={(e) => {
										e.target.src = defaultAvatar;
									}}
									alt={t("components.TopNav.img-alt")}
								/>
							) : (
								<ProfileIcon />
							)}
						</div>
					</div>
					{toggleUserDropdown && (
						<ul className="user-dropdown-menu">
							<li onClick={handleLogout}>{t("components.TopNav.logout")}</li>
						</ul>
					)}
				</div>
			</div>
			<Alert
				message={t("components.TopNav.info-alert")}
				confirm={t("button.check")}
				type="info"
			/>
		</nav>
	);
};

export default TopNav;
