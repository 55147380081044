import React from "react";
import { Navigate } from "react-router";
import { WithAuthorization, WithoutAuthorization } from "./Authorization";
import { createBrowserRouter } from "react-router-dom";
import WithAuthRouter from "./WithAuthRouter";
import WithoutAuthRouter from "./WithoutAuthRouter";

//New Landing
import LandingPage from "~/pages/LandingPage";
import CasePage from "~/pages/LandingPage/CasePage";

// Auth
import JoinPage from "~/pages/auth/JoinPage";
import JoinConfirmPage from "~/pages/auth/JoinPage/JoinConfirm";
import LoginPage from "~/pages/auth/LoginPage";
import FindPwPage from "~/pages/auth/FindPwPage/Find";
import ChangePwPage from "~/pages/auth/FindPwPage/Change";
import TermsPage from "~/pages/auth/TermsPage";
import PrivacyPage from "~/pages/auth/PrivacyPage";

// Dashboard
import DashboardPage from "~/pages/DashboardPage";

// Company
import CompanyInfoPage from "~/pages/company/InfoPage";
import CompanyProductListPage from "~/pages/company/ProductPage/ProductList";
import CompanyProductDetailPage from "~/pages/company/ProductPage/ProductView";
import CompanyProductEditPage from "~/pages/company/ProductPage/ProductEdit";
import CompanyMemberPage from "~/pages/company/MemberPage";
import CompanyInvestPage from "~/pages/company/InvestPage";

// Hr
import HrTeamInfoPage from "~/pages/hr/TeamInfoPage";
import HrOrganizationPage from "~/pages/hr/OrganizationPage";
import HrSettingPage from "~/pages/hr/HrSettingPage";
import HrSettingPayDay from "~/pages/hr/HrSettingPage/PayDay";
import HrSettingWorkType from "~/pages/hr/HrSettingPage/WorkType";

import HrSettingAllowance from "~/pages/hr/HrSettingPage/Allowance";
import AttendancePage from "~/pages/hr/AttendancePage";
import MySchedule from "~/pages/hr/AttendancePage/MySchedule/View";
import TeamSchedule from "~/pages/hr/AttendancePage/TeamSchedule";
import VacationPage from "~/pages/hr/VacationPage";
// import Expected from "~/pages/hr/VacationPage/Expected";
// import Used from "~/pages/hr/VacationPage/Used";
// import All from "~/pages/hr/VacationPage/All";
import ManagePage from "~/pages/hr/ManagePage";

// Ir
import IrListPage from "~/pages/ir/IrList";
import IrDetailPage from "~/pages/ir/IrDetail";

import IrBasicInfo from "~/pages/ir/IrDetail/BasicInfo";
import IrHistory from "~/pages/ir/IrDetail/History";
import IrMember from "~/pages/ir/IrDetail/Member";
import IrShareholder from "~/pages/ir/IrDetail/Shareholder";
import IrFinance from "~/pages/ir/IrDetail/Finance";
import IrProduct from "~/pages/ir/IrDetail/Product";
import IrOutcome from "~/pages/ir/IrDetail/Outcome";

// Invest
import EvaluateListPage from "~/pages/invest/EvaluatePage";
import EvaluateDetailPage from "~/pages/invest/EvaluatePage/EvaluateDetail";
import RoundPage from "~/pages/invest/RoundPage";
import SettingPage from "~/pages/invest/settingPage/setting";

// Shareholder
import ShareholderListPage from "~/pages/ShareholderPage/ShareholderList";
import ShareholderDetailPage from "~/pages/ShareholderPage/ShareholderDetail";
import ShareholderAdd from "~/pages/ShareholderPage/ShareholderDetail/ShareholderAdd";

// Vc
import VcList from "~/pages/vc/VcList";
import VcDetail from "~/pages/vc/VcDetail";

// Tax
import TaxDashboardPage from "~/pages/Tax/Dashboard";
import Setting from "~/pages/Tax/Setting";

// Mypage
import AccountPage from "~/pages/mypage/AccountPage";
import PasswordPage from "~/pages/mypage/PasswordPage";
import SignaturePage from "~/pages/mypage/SignaturePage";
import AlarmPage from "~/pages/mypage/AlarmPage";
import PluginPage from "~/pages/mypage/PluginPage";
import NotFoundPage from "~/pages/NotFoundPage";
import AdminTeamPage from "~/pages/mypage/admin/TeamPage";
import AdminAuthPage from "~/pages/mypage/admin/AuthPage";
import AdminBusinessPage from "~/pages/mypage/admin/BusinessPage";
import AdminSignaturePage from "~/pages/mypage/admin/SignaturePage";
import AdminLogoPage from "~/pages/mypage/admin/LogoPage";
import AdminVcConnectPage from "~/pages/mypage/admin/VcConnectPage";
import AdminLicensePage from "~/pages/mypage/admin/LicensePage";
import AdminPaymentPage from "~/pages/mypage/admin/PaymentPage";
import AdminPaymentHistoryPage from "~/pages/mypage/admin/PaymentPage/historyView";
import CallbackPage from "~/pages/mypage/admin/LicensePage/callback";

//External Round
import ExternalRoundPage from "~/pages/ExternalRoundPage";

// Community
import NoticeListPage from "~/pages/community/NoticePage/NoticeList";
import NoticeViewPage from "~/pages/community/NoticePage/NoticeView";
import BoardPage from "~/pages/community/BoardPage/BoardList";
import BoardAddPage from "~/pages/community/BoardPage/BoardList/Edit";
import BoardPostList from "~/pages/community/BoardPage/BoardPost/List";
import BoardPostAdd from "~/pages/community/BoardPage/BoardPost/Detail/BoardPostAdd";
import BoardPostDetail from "~/pages/community/BoardPage/BoardPost/Detail";
import MyPostPage from "~/pages/community/MyPostPage";
import NoticeEdit from "~/pages/community/NoticePage/NoticeEdit";
import BaseRouter from "./BaseRouter";
import InternalServerError from "~/pages/InternalServerError";

// Vacation
import VacationList from '~/pages/vacation/VacationListPage'
import Expected from "~/pages/vacation/VacationListPage/Expected";
import Used from "~/pages/vacation/VacationListPage/Used";
import All from "~/pages/vacation/VacationListPage/All";
import HrSettingVacation from "~/pages/vacation/SettingPage/View";
import VacationManagement from "~/pages/vacation/ManagePage"
import VacationSetting from '~/pages/vacation/SettingPage'


// management
import ManageProfilePage from "~/pages/management/ProfilePage";
import ManagePwPage from "~/pages/management/PwPage";
import ManageSignaturePage from "~/pages/management/SignaturePage";
import ManageLicensePage from "~/pages/management/LicensePage";
import ManagePaymentPage from "~/pages/management/PaymentPage";

// persona
import PersonaLanding from "~/pages/PersonaLanding"
import PricingPage from '~/pages/pricing'
import WhyUsPage from '~/pages/why-us'
import EORPage from '~/pages/eor'
import HrManagementPage from "~/pages/hr-management";

const RouterObject = [
	// {
	// 	path: "/",
	// 	element: <LandingPage />,
	// 	withAuthorization: false,
	// 	restricted: false,
	// 	hasBaseRouter: true,
	// },
 {
		path: "/",
		element: <PersonaLanding />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
 {
		path: "/home",
		element: <PersonaLanding />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
 {
		path: "pricing",
		element: <PricingPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
 {
		path: "why-us",
		element: <WhyUsPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
 {
		path: "eor",
		element: <EORPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
 {
		path: "hr-management",
		element: <HrManagementPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
	{
		path: "case",
		element: <CasePage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
	{
		path: "join/confirm",
		element: <JoinConfirmPage />,
		withAuthorization: false,
		restricted: true,
		hasBaseRouter: true,
	},
	{
		path: "findpw/find",
		element: <FindPwPage />,
		withAuthorization: false,
		restricted: true,
		hasBaseRouter: true,
	},
	{
		path: "findpw/change",
		element: <ChangePwPage />,
		withAuthorization: false,
		restricted: true,
		hasBaseRouter: true,
	},
	{
		path: "policy/privacy",
		element: <PrivacyPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
	{
		path: "policy/terms",
		element: <TermsPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
	{
		path: "externalRound",
		element: <ExternalRoundPage />,
		withAuthorization: false,
		restricted: false,
		hasBaseRouter: true,
	},
	{
		path: "login",
		element: <LoginPage />,
		withAuthorization: false,
		restricted: true,
		hasBaseRouter: true,
	},
	{
		path: "join",
		element: <JoinPage />,
		withAuthorization: false,
		restricted: true,
		hasBaseRouter: true,
	},
	{
		path: "dashboard",
		element: <DashboardPage />,
		withAuthorization: true,
		hasBaseRouter: true,
	},
	{
		path: "company",
		children: [
			{
				path: "info",
				element: <CompanyInfoPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "product",
				children: [
					{
						path: "",
						element: <CompanyProductListPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "view/:idx",
						element: <CompanyProductDetailPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "edit/:idx",
						element: <CompanyProductEditPage isEdit />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "add",
						element: <CompanyProductEditPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{
				path: "member",
				element: <CompanyMemberPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "invest",
				element: <CompanyInvestPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		// 기업정보 -> 내부 세 탭
			{
				path: "admin/business", // 사업자 인증
				element: <AdminBusinessPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "admin/signature", // 법인인감관리
				element: <AdminSignaturePage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "admin/logo", // 로고관리
				element: <AdminLogoPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		],
	},
	{
		path: "vacation",
		children: [
			{
				path: "request",
				element: <VacationList />,
				withAuthorization: true,
				children: [
					{ path: "", element: <Navigate to="used" />, withAuthorization: true },
					{
						path: "used",
						element: <Used />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "expected",
						element: <Expected />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "all",
						element: <All />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{
				path: "management",
				element: <VacationManagement />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "setting",
				element: <VacationSetting />,
				children: [
					{ path: "", element: <Navigate to="payday" />, withAuthorization: true },
					{
						path: "payday",
						element: <HrSettingPayDay />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "worktype",
						element: <HrSettingWorkType />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "vacation",
						element: <HrSettingVacation />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "allowance",
						element: <HrSettingAllowance />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
		],
	},
	{
		path: "management",
		children: [
			{
				path: "profile",
				element: <ManageProfilePage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "pw",
				element: <ManagePwPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "signature",
				element: <ManageSignaturePage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "license",
				element: <ManageLicensePage />,
				withAuthorization: true,
				hasBaseRouter: true,
				children: [
					{
						path: "",
						element: <AdminLicensePage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "success",
						element: <CallbackPage info="success" />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "fail",
						element: <CallbackPage info="fail" />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{
				path: "payment",
				element: <ManagePaymentPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "alarm/list",
				element: <AlarmPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "plugin/list",
				element: <PluginPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		]
	},
	{
		path: "plugin",
		children: [
			{
				path: "list",
				element: <PluginPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		]
	},
	{
		path: "hr",
		children: [
			{ path: "", element: <HrTeamInfoPage />, withAuthorization: true, hasBaseRouter: true },
			{
				path: "list",
				element: <HrTeamInfoPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "organization",
				element: <HrOrganizationPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "setting",
				element: <HrSettingPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "setting",
				element: <HrSettingPage />,
				children: [
					{ path: "", element: <Navigate to="payday" />, withAuthorization: true },
					{
						path: "payday",
						element: <HrSettingPayDay />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "worktype",
						element: <HrSettingWorkType />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					// {
					// 	path: "vacation",
					// 	element: <HrSettingVacation />,
					// 	withAuthorization: true,
					// 	hasBaseRouter: true,
					// },
					{
						path: "allowance",
						element: <HrSettingAllowance />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{
				path: "attendance",
				element: <AttendancePage />,
				withAuthorization: true,
				children: [
					{ path: "", element: <Navigate to="my" />, withAuthorization: true },
					{
						path: "my",
						element: <MySchedule />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "team",
						element: <TeamSchedule />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{
				path: "manage",
				element: <ManagePage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		],
	},
	{
		path: "ir",
		children: [
			{ path: "list", element: <IrListPage />, withAuthorization: true, hasBaseRouter: true },
			{
				path: ":idx",
				element: <IrDetailPage />,
				withAuthorization: true,
				children: [
					{
						path: "",
						element: <Navigate to="basic" />,
						withAuthorization: true,
					},
					{
						path: "basic",
						element: <IrBasicInfo />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "history",
						element: <IrHistory />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "member",
						element: <IrMember />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "shareholder",
						element: <IrShareholder />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "finance",
						element: <IrFinance />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "product",
						element: <IrProduct />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "outcome",
						element: <IrOutcome />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
		],
	},
	{
		path: "invest",
		withAuthorization: true,
		children: [
			{
				path: "review",
				withAuthorization: true,
				children: [
					{ path: "", element: <EvaluateListPage />, withAuthorization: true },
					{
						path: "detail/:idx",
						element: <EvaluateDetailPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{ path: "round", element: <RoundPage />, withAuthorization: true, hasBaseRouter: true },
			{
				path: "setting",
				element: <SettingPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		],
	},
	{
		path: "shareholder",
		withAuthorization: true,
		children: [
			{
				path: "list",
				element: <ShareholderListPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "add",
				element: <ShareholderAdd />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "detail/:idx",
				element: <ShareholderDetailPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		],
	},
	{
		path: "vc",
		withAuthorization: true,
		children: [
			{
				path: "info",
				children: [
					{ path: "", element: <VcList />, withAuthorization: true },
					{
						path: "view/:idx",
						element: <VcDetail />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
		],
	},
	{
		path: "tax",
		withAuthorization: true,
		children: [
			{ path: "/tax", element: <Navigate to="dashboard" />, withAuthorization: true },
			// { path: "dashboard", element: <Dashboard /> },
			{
				path: "dashboard",
				element: <TaxDashboardPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{ path: "setting", element: <Setting />, withAuthorization: true, hasBaseRouter: true },
		],
	},
	{
		path: "community",
		withAuthorization: true,
		children: [
			{
				path: "notice",
				withAuthorization: true,
				children: [
					{
						path: "",
						element: <NoticeListPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "detail/:idx",
						children: [
							{
								path: "",
								element: <NoticeViewPage />,
								withAuthorization: true,
								hasBaseRouter: true,
							},
							{
								path: "edit",
								element: <NoticeEdit isEdit />,
								withAuthorization: true,
								hasBaseRouter: true,
							},
						],
					},
					{
						path: "write",
						element: <NoticeEdit />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
				],
			},
			{
				path: "list",
				withAuthorization: true,
				children: [
					{
						path: "",
						element: <BoardPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "add",
						element: <BoardAddPage />,
						withAuthorization: true,
						hasBaseRouter: true,
					},
					{
						path: "board/:idx",
						withAuthorization: true,
						children: [
							{
								path: "",
								element: <BoardPostList />,
								withAuthorization: true,
								hasBaseRouter: true,
							},
							{
								path: "add",
								element: <BoardPostAdd />,
								withAuthorization: true,
								hasBaseRouter: true,
							},
							{
								path: "post/:postIdx",
								element: <BoardPostDetail />,
								withAuthorization: true,
								hasBaseRouter: true,
							},
						],
					},
				],
			},
			{
				path: "my-page",
				element: <MyPostPage />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
		],
	},
	{
		path: "mypage",
		withAuthorization: true,
		children: [
			// {
			// 	path: "admin/business", // 사업자 인증
			// 	element: <AdminBusinessPage />,
			// 	withAuthorization: true,
			// 	hasBaseRouter: true,
			// },
			// {
			// 	path: "admin/signature", // 법인인감관리
			// 	element: <AdminSignaturePage />,
			// 	withAuthorization: true,
			// 	hasBaseRouter: true,
			// },
			// {
			// 	path: "admin/logo", // 로고관리
			// 	element: <AdminLogoPage />,
			// 	withAuthorization: true,
			// 	hasBaseRouter: true,
			// },
			// {
			// 	path: "admin/license", // 라이센스
			// 	withAuthorization: true,
			// 	children: [
			// 		{
			// 			path: "",
			// 			element: <AdminLicensePage />,
			// 			withAuthorization: true,
			// 			hasBaseRouter: true,
			// 		},
			// 		{
			// 			path: "success",
			// 			element: <CallbackPage info="success" />,
			// 			withAuthorization: true,
			// 			hasBaseRouter: true,
			// 		},
			// 		{
			// 			path: "fail",
			// 			element: <CallbackPage info="fail" />,
			// 			withAuthorization: true,
			// 			hasBaseRouter: true,
			// 		},
			// 	],
			// },
			// {
			// 	path: "admin/payment", // 청구
			// 	withAuthorization: true,
			// 	children: [
			// 		{
			// 			path: "",
			// 			element: <AdminPaymentPage />,
			// 			withAuthorization: true,
			// 			hasBaseRouter: true,
			// 		},
			// 		{
			// 			path: "detail/:idx",
			// 			element: <AdminPaymentHistoryPage />,
			// 			withAuthorization: true,
			// 			hasBaseRouter: true,
			// 		},
			// 	],
			// },
			// ?????????????
			// {
			// 	path: "admin/vcconnect",
			// 	element: <AdminVcConnectPage />,
			// 	withAuthorization: true,
			// 	hasBaseRouter: true,
			// },
			{
				path: "404",
				element: <NotFoundPage type="myPage" />,
				withAuthorization: true,
				hasBaseRouter: true,
			},
			{
				path: "/mypage/*",
				element: <Navigate to="404" />,
				withAuthorization: true,
			},
		],
	},
	{
		path: "404",
		element: <NotFoundPage type="main" />,
		withAuthorization: true,
		hasBaseRouter: true,
	},
	{
		path: "500",
		element: <InternalServerError type="main" />,
		withAuthorization: true,
		hasBaseRouter: true,
	},
	{ path: "*", element: <Navigate to="/404" />, withAuthorization: true },
];

const commonRouter = (router) => ({
	path: router.path,
	element: router.element ? (
		router.withAuthorization ? (
			<WithAuthorization>
				{router.hasBaseRouter && <BaseRouter />}
				<WithAuthRouter />
				{router.element}
			</WithAuthorization>
		) : (
			<WithoutAuthorization restricted={router.restricted}>
				{router.hasBaseRouter && <BaseRouter />}
				<WithoutAuthRouter />
				{router.element}
			</WithoutAuthorization>
		)
	) : null,
});

const hasChildren = (router) => ({
	...commonRouter(router),
	children: router.children.map((item) => isChidrenMap(item)),
});

const hasntChildren = (router) => commonRouter(router);

const isChidrenMap = (router) => {
	if (!router.children) return hasntChildren(router);
	return hasChildren(router);
};

export const routers = createBrowserRouter(
	RouterObject.map((router) => {
		return {
			...commonRouter(router),
			children: router.children ? router.children.map((item) => isChidrenMap(item)) : [],
		};
	})
);
