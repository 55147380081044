import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";

// Components
import LandingHeader from "~/common/components/LandingHeader";
import LandingFooter from "~/common/components/LandingFooter";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { Helmet } from "react-helmet-async";
import { getTitle } from "~/utils/getTitle";
import { ProFreeIcon } from "~/assets/svg/InterfacesIcon";
import { CloseIconResponsive } from "~/assets/svg/LandingIcon";
import { useAtom } from "jotai";
import { promotionModalAtom, bookDemoModalAtom } from "~/atoms/Landing";
import { useGlobalStore } from "~/store";
import useScrollTop from "~/common/hooks/useScrollTop";
import { Trans, useTranslation } from "react-i18next";

const LandingLayout = ({ children }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [promotionModal, setPromotionModal] = useAtom(promotionModalAtom);
	const [bookDemoModal, setBookDemoModal] = useAtom(bookDemoModalAtom);
	const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
	const [sendEmailFail, setSendEmailFail] = useState(false);

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		companyName: "",
		businessEmail: "",
		primaryGoal: t("BookDemoModal.input.primary-goal.answer-1"),
	});

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs
			.send(
				"service_eqzutmx", // Replace with your Service ID
				"template_nyy78ws", // Replace with your Template ID
				{
					firstName: formData.firstName,
					lastName: formData.lastName,
					companyName: formData.companyName,
					businessEmail: formData.businessEmail,
					primaryGoal: formData.primaryGoal,
				},
				"-ME9auilYGhqxS1Me" // Replace with your User ID
			)
			.then((result) => {
				setSendEmailSuccess(true);
				setSendEmailFail(false);
				handleClose();
				clearInput();
			})
			.catch((error) => {
				setSendEmailFail(true);
				setSendEmailSuccess(false);
				handleClose();
			});
	};

 const handleClose = () => {
  setBookDemoModal(false)
  clearInput()
 }

	const clearInput = () => {
		setFormData({
			firstName: "",
			lastName: "",
			companyName: "",
			businessEmail: "",
			primaryGoal: t("BookDemoModal.input.primary-goal.answer-1"),
		});
	};

	const [
		{
			user: { info },
		},
	] = useGlobalStore();

	useScrollTop();

	return (
		<>
			<main className={"p__newMain"}>
				<LandingHeader />
				{children}
				<LandingFooter />
			</main>
			<ModalTemplate
				modalState={bookDemoModal}
				setModalToggle={setBookDemoModal}
				width="100%"
			>
				<form onSubmit={sendEmail}>
					<div className="p-8">
						<div
							onClick={() => handleClose()}
							className="cursor-pointer float-right"
						>
							<CloseIconResponsive />
						</div>
						<div className="text-xl font-bold text-center text-green-950 py-4">
							{t("BookDemoModal.header")}
						</div>
						<div className="pb-12 text-justify">{t("BookDemoModal.description")}</div>
						<div className="flex justify-between w-full gap-2">
							<div className="w-full">
								<div> {t("BookDemoModal.input.first-name")}</div>
								<input
									name="firstName"
									value={formData.firstName}
									onChange={handleChange}
									className="border border-slate-300 border-solid py-1 px-2 w-full rounded-lg"
									placeholder={t("BookDemoModal.input.first-name")}
								/>
							</div>
							<div className="w-full">
								<div>{t("BookDemoModal.input.last-name")}</div>
								<input
									name="lastName"
									value={formData.lastName}
									onChange={handleChange}
									className="border border-slate-300 border-solid py-1 px-2 w-full rounded-lg"
									placeholder={t("BookDemoModal.input.last-name")}
								/>
							</div>
						</div>
						<div className="w-full py-2">{t("BookDemoModal.input.company-name")}</div>
						<input
							name="companyName"
							value={formData.companyName}
							onChange={handleChange}
							className="border border-slate-300 border-solid py-1 px-2 w-full rounded-lg"
							placeholder={t("BookDemoModal.input.company-name")}
						/>
						<div className="w-full py-2">{t("BookDemoModal.input.email")}</div>
						<input
							name="businessEmail"
							value={formData.businessEmail}
							onChange={handleChange}
							className="border border-slate-300 border-solid py-1 px-2 w-full rounded-lg"
							placeholder={t("BookDemoModal.input.business-email")}
						/>
						<div className="w-full py-2">
							{t("BookDemoModal.input.primary-goal.question")}
						</div>
						<select
							name="primaryGoal"
							value={formData.primaryGoal}
							onChange={handleChange}
							className="border border-slate-300 border-solid py-1 px-2 w-full rounded-lg"
						>
							<option value={t("BookDemoModal.input.primary-goal.answer-1")}>
								{t("BookDemoModal.input.primary-goal.answer-1")}
							</option>
							<option value={t("BookDemoModal.input.primary-goal.answer-2")}>
								{t("BookDemoModal.input.primary-goal.answer-2")}
							</option>
							<option value={t("BookDemoModal.input.primary-goal.answer-3")}>
								{t("BookDemoModal.input.primary-goal.answer-3")}
							</option>
						</select>
						<div className="text-xs py-6 text-nowrap">
							{t("BookDemoModal.terms")}&nbsp;
							{/* <span className="underline text-green-950 text-xs cursor-pointer">
       {t("BookDemoModal.privacy-policy")}
       </span> */}
						</div>
						<button
							type="submit"
							className="bg-green-950 rounded-xl w-full text-white py-2"
						>
							{t("BookDemoModal.book-a-demo")}
						</button>
					</div>
				</form>
			</ModalTemplate>

			{/* Show success modal */}
			{sendEmailSuccess && (
				<ModalTemplate
					modalState={sendEmailSuccess}
					setModalToggle={setSendEmailSuccess}
					width="100%"
					height="100%"
				>
					<div className="p-8">
						<div
							onClick={() => setSendEmailSuccess(false)}
							className="cursor-pointer float-right"
						>
							<CloseIconResponsive />
						</div>
						<div className="text-xl font-bold text-center text-green-950 py-4">
							{t("BookDemoModal.notification.notification")}
						</div>
						<div className="pb-12 text-justify">{t("BookDemoModal.notification.success")}</div>
						<button
							onClick={() => setSendEmailSuccess(false)}
							className="bg-green-950 rounded-xl w-full text-white py-2"
						>
							{t("BookDemoModal.notification.close")}
						</button>
					</div>
				</ModalTemplate>
			)}

			{/* Show failure modal */}
			{sendEmailFail && (
				<ModalTemplate
    modalState={sendEmailFail}
    setModalToggle={setSendEmailFail}
    width="100%"
    height="100%"
   >
    <div className="p-8">
     <div
      onClick={() => setSendEmailFail(false)}
      className="cursor-pointer float-right"
     >
      <CloseIconResponsive />
     </div>
     <div className="text-xl font-bold text-center text-green-950 py-4">
      {t("BookDemoModal.notification.notification")}
     </div>
     <div className="pb-12 text-justify">{t("BookDemoModal.notification.fail")}</div>
     <button
      onClick={() => setSendEmailFail(false)}
      className="bg-green-950 rounded-xl w-full text-white py-2"
     >
      {t("BookDemoModal.notification.close")}
     </button>
    </div>
   </ModalTemplate>
			)}

			{/* Pro Free 안내모달 */}
			{/* <ModalTemplate
				modalState={promotionModal}
				setModalToggle={setPromotionModal}
				width={430}
				height={264}
				className="pro-free-modal"
			>
				<Helmet>
					<title>{getTitle(t("Landing.LandingLayout.ProFreeModal.title"))}</title>
				</Helmet>
				<div className="modal-content">
					<ProFreeIcon />
					<p className="title pre">
						<Trans
							i18nKey="Landing.LandingLayout.ProFreeModal.content"
							components={[<b />]}
						/>
					</p>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => {
							setPromotionModal(false);
						}}
					>
						{t("button.close")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={() => {
							setPromotionModal(false);
							navigate(
								!info.role
									? "/join"
									: info.role === "admin"
									? "/myPage/admin/license"
									: "/dashboard"
							);
						}}
					>
						{t("Landing.LandingLayout.ProFreeModal.btn-free")}
					</button>
				</div>
			</ModalTemplate> */}
		</>
	);
};

export default LandingLayout;
