import React, {useEffect, useState} from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";

// Assets
import "~/assets/sass/pages/mypage/account/account.scss";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import { useTranslation } from "react-i18next";
import {Case, If, OtherWise} from "~/common/components/If";
import CardFront from "~/pages/management/ProfilePage/CardFront";
import CardBack from "~/pages/management/ProfilePage/CardBack";
import Loading from "~/common/components/Loading";
import Edit from "~/pages/mypage/AccountPage/Edit";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import MemberDetailModal from "~/pages/hr/TeamInfoPage/modal/MemberDetailModal";

import { HR_GET_MEMBER, HR_GET_ORG, HR_GET_WORK_TYPE } from "~/store/organization/repository";

import Modal from '~/pages/management/ProfilePage/Modal'
import useRepository from "~/common/hooks/useRepository";
import {clearOrgData} from "~/store/organization/actions";
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import useAlert from "~/common/hooks/useAlert";
import {getChildren} from "~/common/components/TreeView/utils";
import translation from "~/translations";

const ProfilePage = () => {


    const { t } = useTranslation();
    const navigate = useNavigate();
    const [{ user, organization }, dispatch] = useGlobalStore();


    const [getWorkTypeList] = useRepository(HR_GET_WORK_TYPE);
    const [getOrgList] = useRepository(HR_GET_ORG);
    const [getMemberList] = useRepository(HR_GET_MEMBER);


    const [memberDetailModal, setMemberDetailModal] = useState(false);
    const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
    const [profileInfo, setProfileInfo] = useState({});

    const [employmentStatusFilter, setEmploymentStatusFilter] = useState("0");
    const [selectedOrg, setSelectedOrg] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [totalMemberCount, setTotalMemberCount] = useState("");

    const EditProfile = () => {
        if (user.info.isHrTeamMember) {
            navigate(`/hr/list?idx=${user.info.idx}`);
        } else {
            setMemberDetailModal(true);
        }
    };

    const teamInviteRequest = useRequest("get", `admin/invite?size=100&page=1`);
    const teamActiveRequest = useRequest("post", `admin/activate`);
    const userDetailRequest = useRequest("get", "user/profile");
    const [errMsg, setErrMsg] = useState("");
    const [showErrorAlert, ErrorAlert] = useAlert();
    const [showInfoAlert, InfoAlert] = useAlert();


    const getInviteList = () => {
        teamInviteRequest.call();
    };
    const informAndGetList = () => {
        showInfoAlert().then(() => getInviteList());
    };


    useEffect(() => {
        if(!user)  return;
        userDetailRequest.singleCall();
    }, [user]);

    useEffect(() => {
        switch (userDetailRequest.state) {
            case API_STATE.done:
                const responseData = userDetailRequest.response.data;
                setProfileInfo({
                    user: {
                        ...responseData.user,
                        ...responseData.userProfile,
                        position: user?.info?.position,
                    },
                    career: responseData.userCareer,
                });
                setPageState(PAGE_STATE.VIEW);
                break;
            case API_STATE.error:
                console.log("error");
                break;
            default:
                break;
        }
    }, [userDetailRequest.state]);


    function replaceValue(value) {
        let newStatus;
        switch (value) {
            case "-1":
                newStatus = [0, 1, 2, 3]; //전체
                break;
            case "0":
                newStatus = [0, 1]; //근무중
                break;
            case "2":
                newStatus = [2]; //휴직
                break;
            case "3":
                newStatus = [3]; //퇴직
                break;
        }

        return newStatus;
    }

    const params = {
        searchWord:  undefined,
        employmentStatus: replaceValue(employmentStatusFilter),
    };

    const handleFilter = () => {
        getOrgList(params);
        getMemberList({ ...params, orgIdList: selectedOrg });
    };

    const handleCallInfo = () => {
        userDetailRequest.call();
    }

    useEffect(() => {
        switch (teamActiveRequest.state) {
            case API_STATE.done:
                informAndGetList();
                break;
            case API_STATE.error:
                console.log("error", teamActiveRequest.error);
                switch (teamActiveRequest.error.statusCode) {
                    case 404:
                        (async () => {
                            await setErrMsg("존재하지 않는 사용자입니다. 관리자에게 문의해주세요.");
                            showErrorAlert();
                        })();
                        break;
                    case 403:
                        (async () => {
                            await setErrMsg("이미 탈퇴한 회원입니다. 관리자에게 문의해주세요.");
                            showErrorAlert();
                        })();
                        break;
                    case 500:
                        navigate("/404");
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }, [teamActiveRequest.state, teamActiveRequest.error, teamActiveRequest.response]);


    useEffect(() => {
        handleFilter();
    }, [employmentStatusFilter]);


    const headerButtons = [
        <button className="btn__solid" color="primary" onClick={EditProfile}>
            {t("button.modify")}
        </button>,
    ];

    useEffect(() => {
        dispatch(clearOrgData());
    }, []);

    return(
        <>
            <NavbarAndSidebarLayout menu={["management", "profile"]} type={"main"}>
                <PageHeader
                    title="내 프로필"
                    mainTitle="계정관리"
                    subTitle="내 프로필"
                    buttonList={headerButtons}
                />
                <If condition={pageState}>
                    <Case condition={PAGE_STATE.VIEW}>
                        <div className="c__card-wrap">
                            <CardFront profile={profileInfo.user} />
                            <CardBack
                                careerList={profileInfo.career}
                                setMemberDetailModal={setMemberDetailModal}
                                handleCallback={() => userDetailRequest.call()}
                            />
                        </div>
                    </Case>
                    <OtherWise>
                        <Loading />
                    </OtherWise>
                </If>
            </NavbarAndSidebarLayout>

            <ModalTemplate
                title={t("MyPage.AccountPage.teamInfo-modal-title")}
                modalState={memberDetailModal}
                setModalToggle={setMemberDetailModal}
                width={800}
                className="teamInfo-modal"
            >
                <Modal
                    selectedMember={profileInfo?.user?.idx && parseInt(profileInfo?.user?.idx)}
                    callbackFunction={handleCallInfo}
                />
            </ModalTemplate>


        </>
    )
}

export default ProfilePage
