import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import OrgViewer from "./OrgViewer";
import UserList from "./UserList/";
import useRepository from "~/common/hooks/useRepository";
import { HR_GET_EXIST_MEMBER, HR_GET_MEMBER, HR_GET_ORG, HR_GET_WORK_TYPE } from "~/store/organization/repository";
import { getChildren } from "~/common/components/TreeView/utils";

//Modal
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import ManageOrgModal from "~/pages/hr/TeamInfoPage/modal/ManageOrgModal";
import MemberDetailModal from "./modal/MemberDetailModal";
import AddMemberModal from "~/pages/hr/TeamInfoPage/modal/AddMemberModal/newIndex";
// import AddMemberModalB from "~/pages/hr/TeamInfoPage/modal/AddMemberModal/index";

// Assets
import "~/assets/sass/pages/hr/list.scss";
import { clearOrgData } from "~/store/organization/actions";
import { useGlobalStore } from "~/store";
import { Case, If } from "~/common/components/If";
import { useTranslation } from "react-i18next";
import useRequest from "~/common/hooks/useRequest";
import translation from "~/translations";
import useAlert from "~/common/hooks/useAlert";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { useForm } from "react-hook-form";
import TabMenu from "~/common/components/TabMenu";
import List from "../AuthPage/list";
import View from "../AuthPage/View";
import Edit from "../AuthPage/Edit";
import { makeRandomPassword } from "~/utils/password";


const TeamInfoPageRe = () => {
	const { t } = useTranslation();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();
	const navigate = useNavigate();
	const [, dispatch] = useGlobalStore();
	const [getWorkTypeList] = useRepository(HR_GET_WORK_TYPE);
	const [getOrgList] = useRepository(HR_GET_ORG);
	const [getMemberList] = useRepository(HR_GET_MEMBER);

	const [resendMember, setResendMember] = useState();
	const resendMailForm = useForm({
		defaultValues: {
			name: resendMember?.name,
			email: resendMember?.id,
			password: makeRandomPassword(),
		}
	});
	const [apiState, setApiState] = useState("loading");

	const [searchParams, setSearchParams] = useSearchParams();
	const idx = searchParams.get("idx");

	const [searchWord, setSearchWord] = useState("");
	const [employmentStatusFilter, setEmploymentStatusFilter] = useState("0");
	const [selectedOrg, setSelectedOrg] = useState([]);
	const [selectedMember, setSelectedMember] = useState(null);
	const [totalMemberCount, setTotalMemberCount] = useState("");

	const [currentTab, setCurrentTab] = useState("team");


	const tabMenuList = info.role === 'admin' ? [
		{ id: "team", title: '팀원 목록' },//translation.t("components.Footer.terms") },
		{ id: "auth", title: '권한 설정' },//translation.t("components.Footer.privacy") },
	] : [
		{ id: "team", title: '팀원 목록' },//translation.t("components.Footer.terms") },
	];

	const headerButtons = [<button
		type="button"
		className="btn__solid"
		color="primary"
		onClick={() => setInviteModal(true)}
	>
		팀원 추가
	</button>,]

	const [page, setPage] = useState(PAGE_STATE.LOADING);
	//팀원 리스트
	const [authMemberList, setAuthMemberList] = useState([]);
	//메뉴 리스트
	const [pluginList, setPluginList] = useState([]);
	//선택된 유저
	const [selectUser, setSelectUser] = useState();
	//권한 리스트
	const [authorityList, setAuthorityList] = useState([]);
	//팀원 리스트 조회
	const authMemberListRequest = useRequest("get", "admin/auth/member");
	//플러그인 리스트 조히
	const pluginListRequest = useRequest("get", "admin/auth/plugin");
	//상세 권한 조회
	const getAuthorityRequest = useRequest("get", "admin/auth/detail");
	//Edit Page Alert Modal
	const [authEditAlertModal, setAuthEditAlertModal] = useState(false);
	const [authEditAlertContent, setAuthEditAlertContent] = useState(false);


	const buttonList =
		page === PAGE_STATE.VIEW
			? [
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => setPage(PAGE_STATE.EDIT)}
				>
					{t("button.Modify")}
				</button>,
			]
			: selectUser?.role === "admin"
				? [
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => {
							setPage(PAGE_STATE.VIEW);
						}}
					>
						{t("button.back")}
					</button>,
				]
				: [
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => {
							setAuthEditAlertModal(true);
							setAuthEditAlertContent(
								<>
									{selectUser.name}
									{t("MyPage.admin.Auth.btn-cancel")}
								</>
							);
							setAlertState("cancel");
						}}
					>
						{t("button.cancel")}
					</button>,
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={() => {
							setAuthEditAlertModal(true);
							setAuthEditAlertContent(
								selectUser.name + t("MyPage.admin.Auth.btn-submit")
							);
							setAlertState("submit");
						}}
					>
						{t("button.application")}
					</button>,
				];


	//플러그인 메뉴 리스트 조회 Request
	useEffect(() => {
		pluginListRequest.singleCall();
	}, [pluginListRequest]);

	//플러그인 메뉴 리스트 조회 Response
	useEffect(() => {
		switch (pluginListRequest.state) {
			case API_STATE.done:
				setPluginList(pluginListRequest.response.data);
				break;
			case API_STATE.error:
				console.log("error", pluginListRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [pluginListRequest.state, pluginListRequest.done, pluginListRequest.error]);

	//팀원 리스트 조회 Request
	useEffect(() => {
		authMemberListRequest.singleCall();
	}, [authMemberListRequest]);

	//팀원 리스트 조회 Response
	useEffect(() => {
		switch (authMemberListRequest.state) {
			case API_STATE.done:
				const newData = authMemberListRequest.response.data.map((d) => ({
					...d,
					roleName:
						d.role === "admin"
							? t("MyPage.Admin")
							: d.role === "user"
								? t("MyPage.User")
								: t("MyPage.Manager"),
				}));
				setAuthMemberList(newData);
				if (selectUser === undefined) {
					setSelectUser(newData[0]);
				} else {
					setSelectUser(newData.find((d) => d.idx === selectUser.idx));
				}
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", authMemberListRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [authMemberListRequest.state, authMemberListRequest.done, authMemberListRequest.error]);

	//선택한 유저 권한 조회 Request
	useEffect(() => {
		if (authMemberList.length > 0 && selectUser !== undefined) {
			getAuthorityRequest.call(selectUser.idx, "path");
		}
	}, [selectUser]);

	//선택한 유저 권한 조회 Response
	useEffect(() => {
		switch (getAuthorityRequest.state) {
			case API_STATE.done:
				let newData = [];

				if (getAuthorityRequest.response.data === "admin") {
					pluginList.forEach((p) => {
						const menuAuth = p.subMenu.map((s) => ({
							...s,
							viewAuth: true,
							editAuth: true,
						}));
						newData.push({ ...p, subMenu: menuAuth });
					});
				} else {
					const viewAuth = getAuthorityRequest.response.data.viewAuth;
					const editAuth = getAuthorityRequest.response.data.editAuth;

					pluginList.forEach((p) => {
						const menuAuth = p.subMenu.map((s) => ({
							...s,
							viewAuth:
								viewAuth.length > 0 &&
									viewAuth.find((v) => v.pluginMenuIdx === s.idx)
									? viewAuth.find((v) => v.pluginMenuIdx === s.idx).auth
									: false,
							editAuth:
								editAuth.length > 0 &&
									editAuth.find((v) => v.pluginMenuIdx === s.idx)
									? editAuth.find((v) => v.pluginMenuIdx === s.idx).auth
									: false,
						}));
						newData.push({ ...p, subMenu: menuAuth });
					});
				}

				setAuthorityList(newData);
				break;
			case API_STATE.error:
				console.log("error", getAuthorityRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [getAuthorityRequest.state, getAuthorityRequest.done, getAuthorityRequest.error]);

	const [manageOrgModal, setManageOrgModal] = useState(false);
	const [memberDetailModal, setMemberDetailModal] = useState(false);
	const [inviteModal, setInviteModal] = useState(false);

	const [alertState, setAlertState] = useState("");
	const [alertModalMsg, setAlertModalMsg] = useState("");
	const [errMsg, setErrMsg] = useState("");



	const [withdrawUser, setWithdrawUser] = useState(null);
	const [resendMailModal, setResendMailModal] = useState(false);

	const teamInviteRequest = useRequest("get", `admin/invite?size=100&page=1`);
	const teamActiveRequest = useRequest("post", `admin/activate`);
	const teamResendMailRequest = useRequest("post", `admin/invite/resend/${resendMember?.idx}`);
	const teamWithdrawRequest = useRequest("post", `admin/withdraw`);
	const memberResignRequest = useRequest("post", `user/resign`);

	const [showInfoAlert, InfoAlert] = useAlert();
	const [showErrorAlert, ErrorAlert] = useAlert();
	const [showWithdrawAlert, WithdrawAlert] = useAlert();
	const [showResignAlert, ResignAlert] = useAlert();
	const [showSuccessAlert, SuccessAlert] = useAlert();
	const [showResendAlert, ResendAlert] = useAlert();
	const [showExternalAlert, ExternalAlert] = useAlert();



	const getInviteList = () => {
		// teamInviteRequest.call();
		getMemberList({ ...params, orgIdList: selectedOrg });
	};

	const informAndGetList = () => {
		showInfoAlert().then(() => getInviteList());
	};

	const submit = ({ password }) => {
		handleResend(password);
	};


	function replaceValue(value) {
		let newStatus;
		switch (value) {
			case "-1":
				newStatus = [0, 1, 2, 3]; //전체
				break;
			case "0":
				newStatus = [0, 1]; //근무중
				break;
			case "2":
				newStatus = [2]; //휴직
				break;
			case "3":
				newStatus = [3]; //퇴직
				break;
		}

		return newStatus;
	}

	const params = {
		searchWord: searchWord.length > 0 ? searchWord : undefined,
		employmentStatus: replaceValue(employmentStatusFilter),
	};

	const handleSearch = () => {
		getOrgList(params);
		getMemberList({ ...params, orgIdList: selectedOrg });
	};

	const handleFilter = () => {
		getOrgList(params);
		getMemberList({ ...params, orgIdList: selectedOrg });
	};

	const handleRowClick = ({ node }) => {
		if (selectedOrg[0] === node.id) {
			setSelectedOrg([]);
			getMemberList({ ...params, orgIdList: [] });
		} else {
			const idList = getChildren(node, "id");
			setSelectedOrg(idList);
			getMemberList({ ...params, orgIdList: idList });
		}
	};

	const clearSelectedOrg = () => {
		setSelectedOrg([]);
		getMemberList({ ...params, orgIdList: [] });
	};

	const getInitialState = () => {
		getOrgList(params);
		getMemberList({ ...params, orgIdList: selectedOrg });
	};

	useEffect(() => {
		dispatch(clearOrgData());
		getWorkTypeList();
		setApiState("done");
	}, []);

	useEffect(() => {
		if (idx) {
			getWorkTypeList().then(() => {
				setSelectedMember(parseInt(idx));
				setMemberDetailModal(true);
				setSearchParams({});
			});
		}
	}, []);

	const ALERT_MODAL_STATE = {
		RESEND: translation.t("MyPage.admin.Team.index.state.resend"),
		ACTIVATE: translation.t("MyPage.admin.Team.index.state.activate"),
		DEACTIVATE: translation.t("MyPage.admin.Team.index.state.deactivate"),
		IS_WITHDRAW: translation.t("MyPage.admin.Team.index.state.is-withdraw"),
		WITHDRAW_DONE: translation.t("MyPage.admin.Team.index.state.withdraw-done"),
		RESIGN_DONE: translation.t("MyPage.admin.Team.index.state.resign-done"),
	};



	//Alert Modal 상태메세지 변경
	useEffect(() => {
		switch (alertState) {

			case ALERT_MODAL_STATE.RESEND:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.resend"));
				break;
			case ALERT_MODAL_STATE.ACTIVATE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.activate"));
				break;
			case ALERT_MODAL_STATE.DEACTIVATE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.deactivate"));
				break;
			case ALERT_MODAL_STATE.WITHDRAW_DONE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.withdraw-done"));
				break;
			case ALERT_MODAL_STATE.RESIGN_DONE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.resign-done"));
				break;
			default:
				break;
		}
	}, [alertState]);


	useEffect(() => {
		switch (teamActiveRequest.state) {
			case API_STATE.done:
				informAndGetList();
				break;
			case API_STATE.error:
				console.log("error", teamActiveRequest.error);
				switch (teamActiveRequest.error.statusCode) {
					case 404:
						(async () => {
							await setErrMsg("존재하지 않는 사용자입니다. 관리자에게 문의해주세요.");
							showErrorAlert();
						})();
						break;
					case 403:
						(async () => {
							await setErrMsg("이미 탈퇴한 회원입니다. 관리자에게 문의해주세요.");
							showErrorAlert();
						})();
						break;
					case 500:
						navigate("/404");
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [teamActiveRequest.state, teamActiveRequest.error, teamActiveRequest.response]);

	useEffect(() => {
		switch (teamWithdrawRequest.state) {
			case API_STATE.done:
				setAlertState(ALERT_MODAL_STATE.WITHDRAW_DONE);
				if (withdrawUser.isTeamMember) {
					handleResign(withdrawUser.idx);
				} else {
					setWithdrawUser(null);
					informAndGetList();
				}
				break;
			case API_STATE.error:
				console.log("error", teamWithdrawRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [teamWithdrawRequest.state, teamWithdrawRequest.error, teamWithdrawRequest.response]);

	useEffect(() => {
		switch (memberResignRequest.state) {
			case API_STATE.done:
				setAlertState(ALERT_MODAL_STATE.RESIGN_DONE);
				setWithdrawUser(null);
				informAndGetList();
				break;
			case API_STATE.error:
				console.log("error", memberResignRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [memberResignRequest.state, memberResignRequest.error, memberResignRequest.response]);

	//활성, 비활성화 기능
	const handleActive = async (idx) => {
		await teamActiveRequest.call(idx, "path");
		// getInviteList()
	};

	//메일 재전송 기능
	const handleResend = (password) => {
		setResendMailModal(false);
		showSuccessAlert().then(() => teamResendMailRequest.call({ password: password }, "body"));
	};

	useEffect(() => {

		switch (teamResendMailRequest.state) {
			case API_STATE.done:
				setAlertState(ALERT_MODAL_STATE.RESEND);
				showInfoAlert()
				break;
			case API_STATE.error:
				console.log("error", teamResendMailRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [teamResendMailRequest.state, teamResendMailRequest.error, teamResendMailRequest.response]);

	//팀원 삭제
	const handleWithdraw = async (user) => {
		// if (user.employmentStatus === 3) {
		// 	handleResign(user.idx)
		// }
		// else {
		await setWithdrawUser({ idx: user.idx, isTeamMember: user.isTeamMember });
		showWithdrawAlert()
			.then(() => teamWithdrawRequest.call(user.idx, "path"))
			.catch(() => setWithdrawUser(null));
		// }
	};

	const handleResign = (idx) => {
		showResignAlert()
			.then(() => memberResignRequest.call(idx, "path"))
			.catch(() => informAndGetList());
	};


	return (
		<>
			<NavbarAndSidebarLayout menu={["hr", "team"]} type={"main"}>
				<PageHeader buttonList={currentTab === 'team' ? headerButtons : buttonList} />
				<div>
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				</div>
				{
					currentTab === 'team' ?

						<div className="p__teamInfoList">
							<If condition={apiState}>
								<Case condition={"done"}>
									<OrgViewer
										setModalToggle={setManageOrgModal}
										{...{
											totalMemberCount,
											selectedOrg,
											handleRowClick,
											clearSelectedOrg,
										}}
									/>
									<UserList
										setModalToggle={setMemberDetailModal}
										{...{
											setSelectedMember,
											searchWord,
											setSearchWord,
											employmentStatusFilter,
											setEmploymentStatusFilter,
											handleSearch,
											handleFilter,
											setTotalMemberCount,
											handleActive,
											handleWithdraw,
											setAlertState,
											setResendMember,
											setResendMailModal,
											getInviteList,
											showExternalAlert
										}}
									/>
								</Case>
							</If>
						</div>
						:
						<div className="p__admin-auth">
							<div className="admin-auth-cardRow">
								<List
									{...{
										page,
										setPage,
										authMemberList,
										selectUser,
										setSelectUser,
										authMemberListRequest,
										info,
									}}
								/>
								<If
									condition={page}
									{...{
										setPage,
										selectUser,
										authorityList,
										authMemberListRequest,
										getAuthorityRequest,
										authEditAlertModal,
										setAuthEditAlertModal,
										authEditAlertContent,
										alertState,
									}}
								>
									<Case condition={PAGE_STATE.VIEW}>
										<View />
									</Case>
									<Case condition={PAGE_STATE.EDIT}>
										<Edit {...{ info }} />
									</Case>
								</If>
							</div>
						</div>
				}
			</NavbarAndSidebarLayout>
			{/* 초대 메일 재전송 모달 */}
			<ModalTemplate
				title="초대 메일 재전송"
				width={700}
				height={343}
				modalState={resendMailModal}
				setModalToggle={setResendMailModal}
				className="modal-resend-mail"
			>
				<div className="modal-content">
					<p className="main-desc">초대할 팀원의 초기 비밀번호를 입력해 주세요.</p>
					<p className="sub-desc">
						팀원이 비밀번호를 잊은 경우 관리자가 직접 비밀번호를 입력해 초대 메일을
						재전송할 수 있습니다.
					</p>
					<div className="f__group-row">
						<Form useForm={resendMailForm}>
							<Input
								name="name"
								className="team-invite-name"
								placeholder={resendMember?.name}
								readonly
							/>
							<Input
								name="email"
								type="email"
								className="team-invite-mail"
								placeholder={resendMember?.id}
								readonly
							/>
							<Input
								name="password"
								className="team-invite-password"
								placeholder={t("Auth.Join.Form.auth-form.password.label")}
								readonly
							// validation={{
							// 	required: "필수 입력 사항 입니다.",
							// 	pattern: {
							// 		value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/,
							// 		message:
							// 			"8~16자 영문 대소문자, 숫자, 특수문자를 사용해주세요.",
							// 	},
							// }}
							/>
						</Form>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setResendMailModal(false)}
					>
						취소
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={resendMailForm.handleSubmit(submit)}
					>
						초대하기
					</button>
				</div>
			</ModalTemplate>
			{/*조직도 설정 모달*/}
			<ModalTemplate
				title={t("HR.TeamInfo.orgSettingModal")}
				modalState={manageOrgModal}
				setModalToggle={setManageOrgModal}
				width={480}
				height={642}
				className="org-setting-modal"
				closeable={false}
			>
				<ManageOrgModal {...{ setSelectedOrg, getInitialState }} />
			</ModalTemplate>
			{/*팀원 상세 모달*/}
			<ModalTemplate
				title={t("HR.Manage.teamInfo")}
				modalState={memberDetailModal}
				setModalToggle={setMemberDetailModal}
				width={800}
				className="teamInfo-modal"
				closeable={false}
			>
				<MemberDetailModal
					{...{
						searchWord,
						selectedOrg,
						selectedMember,
						handleSearch,
						getInitialState,
					}}
				/>
			</ModalTemplate>
			{/*팀원 추가 모달*/}
			<AddMemberModal
				inviteModal={inviteModal}
				setInviteModal={setInviteModal}
				authMemberListRequest={authMemberListRequest}
				teamInviteRequest={getInviteList}
			/>
			<InfoAlert type="info" message={alertModalMsg} confirm={t("button.check")} />

			<WithdrawAlert
				type="warning"
				message={t("MyPage.admin.Team.index.content.withdrawAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
			/>
			<ResignAlert
				type="warning"
				message={t("MyPage.admin.Team.index.content.resignAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
			/>
			<SuccessAlert
				message={
					<>
						<h3 className="main-desc">팀원에게 초대 메일을 재전송합니다.</h3>
						<p className="sub-desc">
							비밀번호 변경 후 초대 메일을 재전송할 경우
							<br /> 기존 비밀번호로는 로그인이 불가합니다.
						</p>
					</>
				}
				className="modal-resend-success"
				confirm="확인"
				reject="취소"
			/>
			<ErrorAlert
				message={errMsg}
				type="error"
				confirm={t("button.check")}
			/>
			<ExternalAlert
				type="success"
				message={"전환 완료되었습니다."}
				confirm={t("button.check")}
			/>
		</>
	);
};

export default TeamInfoPageRe;
