import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const AuthFooter = () => {
	const { t } = useTranslation();

	return (
		<footer className="l__authFooter">
			<ul>
				<li>(주)붐코커뮤니케이션</li>
				<li>대표자:박경훈</li>
				<li>사업자등록번호:494-86-01834</li>
				<li>통신판매업:2022-서울마포-0684</li>
				<li>{t("components.Footer.CompanyInfo.4")}</li>
				<li>02-6378-0001</li>
				<li>cs@boomco.org</li>
			</ul>
			<ul>
				<li>
					<Trans i18nKey={"components.Footer.copyright"} components={[<b />]} />
				</li>
				<li>
					<Link to={"/policy/terms"} target="_blank">
						{t("components.Footer.terms")}
					</Link>
				</li>
				<li>
					<Link to={"/policy/privacy"} target="_blank" className="btn-policy">
						{t("components.Footer.privacy")}
					</Link>
				</li>
			</ul>
		</footer>
	);
};

export default AuthFooter;
