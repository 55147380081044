import React, { useEffect, useState } from "react";
import TrendChart from "~/common/components/TrendChart";
import { addMonths, parse } from "date-fns";
import { range } from "~/utils/array";
import { dateToString } from "~/utils/formatter";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const EmployNumTrend = ({ data, pageState }) => {
	const { t } = useTranslation();
	const [totalView, setTotalView] = useState(true);
	const [resignedView, setResignedView] = useState(true);
	const [hiredView, setHiredView] = useState(true);

	const mapTable = {
		totalCnt: totalView && {
			name: translation.t("Dashboard.Contents.EmployNumTrend.chart-caption.0"),
			color: "#00a4ff",
			lineColor: "#CECECE",
			type: "line",
		},
		resignedCnt: resignedView && {
			name: translation.t("Dashboard.Contents.EmployNumTrend.chart-caption.2"),
			color: "#CECECE",
			type: "bar",
		},
		hiredCnt: hiredView && {
			name: translation.t("Dashboard.Contents.EmployNumTrend.chart-caption.1"),
			color: "#00a4ff",
			type: "bar",
		},
	};

	const [reducedData, setReducedData] = useState([]);

	useEffect(() => {
		setReducedData(reduce(data));
	}, [data]);

	const reduce = (data) => {
		const lastMonth = data?.[data.length - 1]?.monthlyRecordDate;
		const lastMonthObject = parse(lastMonth, "yyyyMM", new Date());
		return range(0, 11).map((i) => {
			const thisMonthObject = addMonths(lastMonthObject, i);
			const thisMonth = dateToString(thisMonthObject, "yyyyMM");
			const { hiredCnt, resignedCnt, totalCnt } =
				data.find(({ monthlyRecordDate }) => monthlyRecordDate === thisMonth) ?? {};
			return {
				hiredCnt: hiredCnt && parseInt(hiredCnt),
				resignedCnt: resignedCnt && parseInt(resignedCnt),
				totalCnt: totalCnt && parseInt(totalCnt),
				date: dateToString(thisMonthObject, "yy.MM"),
			};
		});
	};

	return (
		<article className="member-vary-chart">
			<div className="member-vary-chart-top">
				<div className="article-title">
					{t("Dashboard.Contents.EmployNumTrend.article-title")}
				</div>
				<div className="chart-caption">
					<button
						type="button"
						onClick={() => setTotalView(!totalView)}
						className={`total ${!totalView && "disabled"}`}
					>
						<span>{t("Dashboard.Contents.EmployNumTrend.chart-caption.0")}</span>
					</button>
					<button
						type="button"
						onClick={() => setHiredView(!hiredView)}
						className={`entering ${!hiredView && "disabled"}`}
					>
						<span>{t("Dashboard.Contents.EmployNumTrend.chart-caption.1")}</span>
					</button>
					<button
						type="button"
						onClick={() => setResignedView(!resignedView)}
						className={`leaving ${!resignedView && "disabled"}`}
					>
						<span>{t("Dashboard.Contents.EmployNumTrend.chart-caption.2")}</span>
					</button>
				</div>
			</div>
			<div className="chart-area">
				<If condition={pageState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						{data.length > 0 ? (
							<TrendChart
								height={200}
								data={reducedData}
								mapTable={mapTable}
								xAxisKey="date"
							/>
						) : (
							<NoData main={t("Dashboard.Contents.EmployNumTrend.no-data")} />
						)}
					</Case>
				</If>
			</div>
		</article>
	);
};

export default EmployNumTrend;
