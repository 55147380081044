import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import { useSetAtom } from "jotai";

// Sections
import NewLandingLayout from "~/layouts/LandingLayout";

// Svg
import {
	RectangleGreen,
	WomanTalking,
	TickIcon,
	AwsIcon,
	AwsIconResponsive,
	AwsWave,
	ArrowRightFull,
	ArrowRight,
	ArrowDown,
	Global,
	GlobalResponsive,
	ShakeHand,
} from "~/assets/svg/LandingIcon";

// Png
import tabletBanner from "~/assets/img/persona/landingPage/tablet.png";
import backgroundWave from "~/assets/img/persona/landingPage/background-wave.png";
import adappterLogo from "~/assets/img/persona/landingPage/logo-ribbon/adappter-logo.png";
import huntLogo from "~/assets/img/persona/landingPage/logo-ribbon/hunt-logo.png";
import illunexLogo from "~/assets/img/persona/landingPage/logo-ribbon/illunex-logo.png";
import boomcoLogo from "~/assets/img/persona/landingPage/logo-ribbon/boomco-logo.png";
import nucncLogo from "~/assets/img/persona/landingPage/logo-ribbon/nucnc-logo.png";
import okceoLogo from "~/assets/img/persona/landingPage/logo-ribbon/okceo-logo.png";
import everywhereLogo from "~/assets/img/persona/landingPage/logo-ribbon/everywhere-logo.png";
import testimonial1 from "~/assets/img/persona/landingPage/testimonials1.png";
import testimonial2 from "~/assets/img/persona/landingPage/testimonials2.png";
import testimonial3 from "~/assets/img/persona/landingPage/testimonials3.png";

// Components
import { bookDemoModalAtom } from "~/atoms/Landing";
import Testimonials from "~/common/components/Testimonials"
import NumbersProved from "~/common/components/NumbersProved";

// Assets
import "~/assets/sass/pages/landing/serviceInfo/index.scss";
import "~/assets/sass/pages/landing/serviceInfo/landingKeyframes.scss";

const LandingPage = () => {
	const { t } = useTranslation();
	const [services, setServices] = useState("global");
	const [activeIndex, setActiveIndex] = useState(null);
	const ribbonLogos = [
		adappterLogo,
		illunexLogo,
		nucncLogo,
		everywhereLogo,
		huntLogo,
		okceoLogo,
		boomcoLogo,
	];

	const setBookDemoModal = useSetAtom(bookDemoModalAtom);

	const handleOnClick = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const serviceItems = [
		{
			id: "global",
			name: t("Landing.Section.services.global.name"),
			title: t("Landing.Section.services.global.title"),
			image: <ShakeHand />,
			description: t("Landing.Section.services.global.description"),
		},
		{
			id: "recruitment",
			name: t("Landing.Section.services.recruitment.name"),
			title: t("Landing.Section.services.recruitment.title"),
			image: <ShakeHand />,
			description: t("Landing.Section.services.recruitment.description"),
		},
		{
			id: "management",
			name: t("Landing.Section.services.management.name"),
			title: t("Landing.Section.services.management.title"),
			image: <ShakeHand />,
			description: t("Landing.Section.services.management.description"),
		},
		{
			id: "eor",
			name: t("Landing.Section.services.eor.name"),
			title: t("Landing.Section.services.eor.title"),
			image: <ShakeHand />,
			description: t("Landing.Section.services.eor.description"),
		},
		{
			id: "platform",
			name: t("Landing.Section.services.platform.name"),
			title: t("Landing.Section.services.platform.title"),
			image: <ShakeHand />,
			description: t("Landing.Section.services.platform.description"),
		},
	];
	const faqItems = [
		{
			id: "0",
			title: t("Landing.Section.faqs.questions.1.question"),
			answer: t("Landing.Section.faqs.questions.1.answer"),
		},
		{
			id: "1",
			title: t("Landing.Section.faqs.questions.2.question"),
			answer: t("Landing.Section.faqs.questions.2.answer"),
		},
		{
			title: t("Landing.Section.faqs.questions.3.question"),
			answer: t("Landing.Section.faqs.questions.3.answer"),
		},
		{
			title: t("Landing.Section.faqs.questions.4.question"),
			answer: t("Landing.Section.faqs.questions.4.answer"),
		},
	];
	const testimonialItems = [
		{
			name: t("Landing.Section.testimonials.testimonial.1.name"),
			position: t("Landing.Section.testimonials.testimonial.1.position"),
			image: testimonial1,
			testimonial: t("Landing.Section.testimonials.testimonial.1.text"),
		},
		{
			name: t("Landing.Section.testimonials.testimonial.2.name"),
			position: t("Landing.Section.testimonials.testimonial.2.position"),
			image: testimonial2,
			testimonial: t("Landing.Section.testimonials.testimonial.2.text"),
		},
		{
			name: t("Landing.Section.testimonials.testimonial.3.name"),
			position: t("Landing.Section.testimonials.testimonial.3.position"),
			image: testimonial3,
			testimonial: t("Landing.Section.testimonials.testimonial.3.text"),
		},
	];
	return (
		<NewLandingLayout>
			<main className="w-full h-full">
				<section className="w-full flex justify-center lg:pb-8 pb-0 relative">
					<div className="absolute top-0 -z-10 mx-auto overflow-auto">
						<img src={backgroundWave} className="overflow-auto" />
					</div>
					<div className="lg:flex block items-center mt-24 max-w-[1920px] w-full">
						<div className="lg:w-[40%] w-[95%] mt-42 m-auto text-left">
							<h1 className="sm:text-5xl text-3xl font-semibold">
								{t("Landing.Section.banner.heading")}
							</h1>
							<div className="text-base text-gray-700 sm:pb-16 pb-8 pt-4 sm:w-[70%] w-full">
								{t("Landing.Section.banner.sub-heading")}
							</div>
							<div className="flex gap-4 sm:pb-0 pb-8">
								<button
									className="bg-green-950 text-white py-3 px-8 rounded-lg font-semibold"
									onClick={() => setBookDemoModal(true)}
								>
									{t("Landing.Section.banner.book-demo-button")}
								</button>
								<button className="text-green-950 bg-[#DEEDF0] py-3 px-8 rounded-lg font-semibold">
									{t("Landing.Section.banner.get-quote-button")}
								</button>
							</div>
						</div>
						<img
							alt="Tablet Background"
							src={tabletBanner}
							className="lg:w-[45%] w-full"
						/>
					</div>
				</section>
				<section>
					<div className="bg-[#042F2E] py-8 mx-auto max-w-[1920px] lg:mt-0 sm:mt-16 mt-0 w-full">
						<Marquee>
							<div className="w-full flex justify-between items-center">
								{ribbonLogos.map((item, index) => (
									<div className="pl-32" key={index}>
										<img src={item} />
									</div>
								))}
							</div>
						</Marquee>
					</div>
				</section>
				<section className="w-[95%] sm:w-[85%] m-auto pt-16 pb-28 max-w-[1920px]">
					<h2 className="text-green-950 m-auto text-center pb-8">
						{t("Landing.Section.services.heading")}
					</h2>
					<div className="overflow-auto">
						<div className="flex lg:gap-16 gap-0 md:justify-center justify-start text-base sm:w-auto w-[800px]">
							{serviceItems.map((item, index) => (
								<button
									className={`py-2 px-4 rounded-lg ${
										item.id === services ? "bg-green-950 text-white" : ""
									}`}
									key={index}
									onClick={() => {
										setServices(item.id);
									}}
								>
									{item.name}
								</button>
							))}
						</div>
					</div>
					<div className="border-b-[1px] pt-4 w-[70%] m-auto" />
					<hr className="border-gray-300 w-[70%] m-auto" />
					{serviceItems.map((item, index) => (
						<div
							key={index}
							className={` m-auto mt-8 gap-16 ${
								item.id === services ? "block" : "hidden"
							}`}
						>
							<div className="grid xl:grid-cols-2 grid-cols-1 gap-4">
								<div className="block mx-auto">{item.image}</div>
								<div className="flex items-end">
									<div className="xl:w-[80%] w-full">
										<h2 className="mb-8">{item.title}</h2>
										<span
											dangerouslySetInnerHTML={{ __html: item.description }}
										/>

										<div className="text-green-950 mt-8 font-bold flex gap-2 underline underline-offset-8 cursor-pointer">
											{t("Landing.Section.services.see-more-button")}
											<ArrowRightFull />
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</section>
				<section className="bg-[#042F2E] text-white mx-auto max-w-[1920px]">
					<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%] flex flex-col gap-20">
						<div className="pt-16">
							<div className="flex justify-between">
								<div>
									<h1 className="text-2xl mt-8">
										{t("Landing.Section.benefit.heading")}
									</h1>
									<h5 className="sm:mt-0 mt-4">
										{t("Landing.Section.benefit.sub-heading")}
									</h5>
								</div>
								<div className="sm:block hidden">
									<RectangleGreen />
								</div>
							</div>
							<div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-8 text-green-900">
								<div className="bg-blue-50 rounded-tr-2xl rounded-bl-2xl p-6 sm:h-60 h-44 cursor-pointer md:hover:mt-[-5px]">
									<h3>
										{t(
											"Landing.Section.benefit.benefit-items.global-talent-access.title"
										)}
									</h3>
									<span>
										{t(
											"Landing.Section.benefit.benefit-items.global-talent-access.description"
										)}
									</span>
								</div>
								<div className="bg-yellow-50 rounded-tr-2xl rounded-bl-2xl p-6 sm:h-60 h-44 cursor-pointer md:hover:mt-[-5px]">
									<h3>
										{t(
											"Landing.Section.benefit.benefit-items.scalability-flexibility.title"
										)}
									</h3>
									<span>
										{t(
											"Landing.Section.benefit.benefit-items.scalability-flexibility.description"
										)}
									</span>
								</div>
								<div className="bg-violet-50 rounded-tr-2xl rounded-bl-2xl p-6 sm:h-60 h-44 cursor-pointer md:hover:mt-[-5px]">
									<h3>
										{t(
											"Landing.Section.benefit.benefit-items.expert-guidance.title"
										)}
									</h3>
									<span>
										{t(
											"Landing.Section.benefit.benefit-items.expert-guidance.description"
										)}
									</span>
								</div>
								<div className="bg-orange-50 rounded-tl-2xl rounded-br-2xl p-6 sm:h-60 h-44 cursor-pointer flex flex-col justify-end items-start md:hover:mt-[-5px]">
									<span>
										{t(
											"Landing.Section.benefit.benefit-items.compliance-assurance.description"
										)}
									</span>
									<h3>
										{t(
											"Landing.Section.benefit.benefit-items.compliance-assurance.title"
										)}
									</h3>
								</div>
								<div className="bg-rose-50 rounded-tl-2xl rounded-br-2xl p-6 sm:h-60 h-44 cursor-pointer flex flex-col justify-end items-start md:hover:mt-[-5px]">
									<span>
										{t(
											"Landing.Section.benefit.benefit-items.swift-onboarding.description"
										)}
									</span>
									<h3>
										{t(
											"Landing.Section.benefit.benefit-items.swift-onboarding.title"
										)}
									</h3>
								</div>
								<div className="bg-green-50 rounded-tl-2xl rounded-br-2xl p-6 sm:h-60 h-44 cursor-pointer flex flex-col justify-end items-start md:hover:mt-[-5px]">
									<span>
										{t(
											"Landing.Section.benefit.benefit-items.adaptability.description"
										)}
									</span>
									<h3>
										{t(
											"Landing.Section.benefit.benefit-items.adaptability.title"
										)}
									</h3>
								</div>
								<div className="mb-4 mt-[-16px]">
									<RectangleGreen />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="bg-slate-100 relative mx-auto max-w-[1920px]">
					<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%] md:flex block justify-between py-32">
						<div className="w-[80%]">
							<h1 className="text-green-950 text-2xl pb-8">
								{t("Landing.Section.faqs.heading")}
							</h1>
							<div>{t("Landing.Section.faqs.sub-heading")}</div>
							<div className="absolute bottom-0">
								<WomanTalking />
							</div>
						</div>
						<div className="w-[100%] md:pb-20 pb-40 md:mt-0 mt-8">
							{faqItems.map((item, index) => (
								<div key={index} className="mb-6">
									<div
										className={`border ${
											index === activeIndex
												? " border border-solid border-green-500"
												: ""
										} bg-white rounded-xl cursor-pointer px-4 py-3 w-full flex justify-between items-center text-base font-semibold text-green-950`}
										onClick={() => handleOnClick(index)}
									>
										{item.title}
										{index === activeIndex ? (
											<div>
												<ArrowDown />
											</div>
										) : (
											<div>
												<ArrowRight />
											</div>
										)}
									</div>
									{index === activeIndex && (
										<div
											dangerouslySetInnerHTML={{ __html: item.answer }}
											className="mt-2 ml-4"
										/>
									)}
								</div>
							))}
						</div>
					</div>
				</section>
    <Testimonials/>
				<NumbersProved />
				<section className="relative py-24 max-w-[1920px] mx-auto">
					<div className="w-[95%] sm:w-[85%] mx-auto">
						<div>
							<h1 className="text-2xl text-green-950">
								{t("Landing.Section.aws.heading1")}
							</h1>
							<h2 className="inline-block text-2xl text-green-950">
								{t("Landing.Section.aws.heading2")} &nbsp;
								<p className="text-2xl text-[#0D9488] font-bold inline-block">
									{t("Landing.Section.aws.heading3")}
								</p>
							</h2>
						</div>
						<span> {t("Landing.Section.aws.sub-heading")}</span>
						<div className="grid lg:grid-cols-2 grid-cols-1 items-center">
							<div className="w-full lg:block sm:flex hidden justify-center">
								<AwsIcon />
							</div>
							<div className="sm:hidden block mx-auto">
								<AwsIconResponsive />
							</div>
							<div className="lg:w-[60%] w-full">
								<div className="flex gap-2">
									<div>
										<TickIcon />
									</div>
									{t("Landing.Section.aws.paragraph1")}
								</div>
								<br />
								<div className="flex gap-2">
									<div>
										<TickIcon />
									</div>
									{t("Landing.Section.aws.paragraph2")}
								</div>
								<div className="text-green-950 mt-8 font-bold flex gap-2 sm:justify-none justify-center">
									<button
										className="bg-green-950 text-white py-3 px-8 rounded-lg font-semibold"
										onClick={() => setBookDemoModal(true)}
									>
										{t("Landing.Section.aws.demo-button")}
									</button>
								</div>
							</div>
						</div>
						<div className="absolute bottom-0 right-0 -z-10 mx-auto">
							<AwsWave />
						</div>
					</div>
				</section>
				<section className="bg-[#042F2E] text-white pt-8 relative max-w-[1920px] m-auto">
					<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%]">
						<div className="lg:w-[50%] w-full">
							<div className="mt-[-16px]">
								<RectangleGreen />
							</div>
							<h1 className="text-green-950 text-2xl text-white">
								{t("Landing.Section.global-expansion.heading")}
							</h1>
							<div className="py-8">
								<span>{t("Landing.Section.global-expansion.sub-heading")}</span>
							</div>
							<div>
								<button
									className="p-4 rounded-xl bg-[#DEEDF0] text-green-950 mb-16 font-bold"
									onClick={() => setBookDemoModal(true)}
								>
									{t("Landing.Section.global-expansion.demo-button")}
								</button>
							</div>
						</div>
					</div>
					<div className="absolute right-0 bottom-0 lg:block hidden">
						<Global />
					</div>
					<div className="relative lg:hidden block flex justify-center">
						<GlobalResponsive />
					</div>
				</section>
			</main>
		</NewLandingLayout>
	);
};

export default LandingPage;
