import React from "react";

// 헤더 세금계산서 아이콘
export const TaxInvoice = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M5.47721 7.95442C5.74076 7.95442 5.95442 7.74076 5.95442 7.47721C5.95442 7.21365 5.74076 7 5.47721 7C5.21365 7 5 7.21365 5 7.47721C5 7.74076 5.21365 7.95442 5.47721 7.95442Z"
				fill="#00A4FF"
			/>
			<path
				d="M6.83349 7.95442C7.09704 7.95442 7.3107 7.74076 7.3107 7.47721C7.3107 7.21365 7.09704 7 6.83349 7C6.56993 7 6.35628 7.21365 6.35628 7.47721C6.35628 7.74076 6.56993 7.95442 6.83349 7.95442Z"
				fill="#00A4FF"
			/>
			<path
				d="M8.18977 7.95442C8.45332 7.95442 8.66698 7.74076 8.66698 7.47721C8.66698 7.21365 8.45332 7 8.18977 7C7.92621 7 7.71256 7.21365 7.71256 7.47721C7.71256 7.74076 7.92621 7.95442 8.18977 7.95442Z"
				fill="#00A4FF"
			/>
			<path
				d="M3.351 9.995H21M3.351 13.019H21M3.351 16.052H21M15.402 5.234V9.986M7.86 10.121V19.4M5.016 5H18.984C20.0974 5 21 5.90259 21 7.016V17.384C21 18.4974 20.0974 19.4 18.984 19.4H5.016C3.90259 19.4 3 18.4974 3 17.384V7.016C3 5.90259 3.90259 5 5.016 5Z"
				stroke="#00A4FF"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};

// 헤더 알림 벨 아이콘
export const HeaderAlarmBellIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M18.7531 14.6844C17.6623 13.0571 17.7189 11.9234 17.7756 10.4829C17.7898 10.1361 17.8039 9.78936 17.8039 9.42257C17.8039 7.46857 16.4936 4.76764 13.7241 3.80065C13.5399 2.7803 12.612 2.00003 11.5 2.00003C10.388 2.00003 9.46008 2.7803 9.27592 3.80065C6.50644 4.76764 5.189 7.47523 5.189 9.42257C5.189 9.78936 5.20316 10.1361 5.21733 10.4829C5.28108 11.9234 5.33066 13.0571 4.24695 14.6844C2.70993 16.9852 2.92242 17.9855 3.18449 18.379C3.32615 18.5924 3.57406 18.7191 3.83613 18.7191H19.1639C19.4259 18.7191 19.6738 18.5924 19.8155 18.379C20.0776 17.9855 20.2901 16.9918 18.7531 14.6844ZM11.5 2.98037C11.9179 2.98037 12.2721 3.19377 12.4987 3.50721C12.18 3.46053 11.8542 3.43385 11.5 3.43385C11.1458 3.43385 10.82 3.4672 10.5013 3.50721C10.7209 3.19377 11.075 2.98037 11.5 2.98037ZM4.62943 17.2319C4.73568 16.8784 4.99067 16.3183 5.55023 15.4846C6.9031 13.4639 6.83227 11.9168 6.76852 10.4229C6.75435 10.0961 6.74019 9.76268 6.74019 9.42257C6.74019 8.00875 7.93722 4.91436 11.5071 4.91436C15.0769 4.91436 16.2669 8.00875 16.2669 9.42257C16.2669 9.76935 16.2527 10.0961 16.2386 10.4229C16.1748 11.9168 16.104 13.4639 17.4569 15.4913C18.0164 16.3249 18.2643 16.8918 18.3776 17.2386H4.62943V17.2319Z" />
			<path d="M13.9226 19.3124C13.5401 19.1391 13.0797 19.2991 12.8956 19.6659C12.6406 20.1861 12.0952 20.5195 11.5002 20.5195C10.9052 20.5195 10.3598 20.1861 10.1048 19.6659C9.92068 19.2991 9.46028 19.1391 9.07779 19.3124C8.68823 19.4858 8.52532 19.926 8.70948 20.2995C9.21946 21.3331 10.3102 22 11.5002 22C12.6902 22 13.7809 21.3331 14.2909 20.2995C14.4751 19.926 14.3051 19.4858 13.9226 19.3124Z" />
		</svg>
	);
};

// 헤더 플러그인 아이콘
export const HeaderPluginIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.9945 6.54068C21.9909 6.46977 21.9773 6.39886 21.9391 6.33341L21.9355 6.33068C21.9336 6.32614 21.9291 6.32341 21.9264 6.31977C21.8873 6.25886 21.8364 6.21432 21.7791 6.17886C21.7709 6.17432 21.7673 6.16523 21.7591 6.16068L12.1973 1.05341C12.0636 0.9825 11.9027 0.981591 11.7682 1.05432L2.24 6.16159C2.09182 6.24068 2 6.39341 2 6.56159V17.978C2 18.1434 2.08909 18.2952 2.23545 18.3752L11.6455 23.5589C11.7291 23.6507 11.8464 23.7098 11.9791 23.7098C11.9909 23.7098 12 23.7043 12.0118 23.7034C12.02 23.7043 12.0282 23.7098 12.0364 23.7098C12.1127 23.7098 12.1882 23.6916 12.2573 23.6534L21.7664 18.3752C21.91 18.2943 22 17.9652 22 17.8007V6.70535C22 6.69808 21.9945 6.54795 21.9945 6.54068ZM2.90909 7.55L6.02273 9.26705V13.738L2.90909 12.218V7.55ZM7.30182 4.47886L10.9545 6.31977L6.48909 8.48704L3.19182 6.68159L7.30182 4.47886ZM16.4355 4.34795L20.6118 6.57795L17.4818 8.39705L12.9745 6.29159L16.4173 4.36341C16.4245 4.35886 16.4282 4.3525 16.4355 4.34795ZM12 16.8916L7.49909 14.5325L11.9736 12.218L16.5627 14.7434L12 16.8916ZM17.0509 13.9743L12.4336 11.4343V7.04159L17.0509 9.19886V13.9743ZM11.5245 11.427L6.93182 13.8025V9.28341L11.5245 7.05341V11.427ZM6.93182 19.8507V15.268L11.5245 17.6871V22.4561L6.91818 19.918C6.92182 19.8952 6.93182 19.8743 6.93182 19.8507ZM12.4336 17.6871L17.0509 15.5243V19.8507C17.0509 19.8834 17.0627 19.9116 17.0691 19.9434L12.4336 22.5161V17.6871ZM17.96 9.17068L21.0909 7.34613V12.3461L17.96 14.0898V9.17068ZM11.9827 1.96977L15.4882 3.84159L11.9682 5.81341L8.28 3.95432L11.9827 1.96977ZM2.90909 13.2552L6.02273 14.767V19.4252L2.90909 17.7089V13.2552ZM17.96 19.4489V15.1152L21.0909 13.2552V17.8007L17.96 19.4489Z"
			/>
		</svg>
	);
};

// 번역 지구 아이콘
export const TranslationIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.5 8.30259C1.85739 9.73681 1.5 11.3267 1.5 13C1.5 14.6733 1.85739 16.2632 2.5 17.6974V17H7.19165C6.40939 14.6879 6.34017 12.4775 6.73639 10.4474C6.83298 9.95251 6.95696 9.46968 7.10435 9H2.5V8.30259ZM2.64092 8H7.46014C8.56288 5.23076 10.4422 2.98949 12.1678 1.52965C7.96488 1.8302 4.38685 4.38938 2.64092 8ZM13.8322 1.52965C18.0351 1.8302 21.6131 4.38938 23.3591 8H18.5399C17.4371 5.23076 15.5578 2.98949 13.8322 1.52965ZM17.4569 8C16.4811 5.74421 14.9588 3.87181 13.5 2.56601V8H17.4569ZM13.5 9H17.8434C18.0238 9.5291 18.1723 10.0761 18.2821 10.639C18.6598 12.5738 18.5852 14.7208 17.748 17H13.5L13.5 9ZM12.5 8V2.56601C11.0412 3.87181 9.51885 5.74421 8.54307 8H12.5ZM8.1566 9H12.5L12.5 17H8.25202C7.41478 14.7208 7.34025 12.5738 7.71787 10.639C7.82772 10.0761 7.97625 9.5291 8.1566 9ZM7.57259 18H2.64092C4.39752 21.6327 8.00866 24.201 12.2449 24.4756C9.96191 22.2818 8.46451 20.1043 7.57259 18ZM13.7551 24.4756C17.9913 24.201 21.6025 21.6327 23.3591 18H18.4274C17.5355 20.1043 16.0381 22.2818 13.7551 24.4756ZM17.3345 18H13.5V23.3242C15.308 21.5048 16.5438 19.7192 17.3345 18ZM12.5 23.3242C10.692 21.5048 9.45615 19.7192 8.66549 18H12.5V23.3242ZM18.8083 17C19.5906 14.6879 19.6598 12.4775 19.2636 10.4474C19.167 9.95251 19.043 9.46968 18.8957 9H23.5V8.30259C24.1426 9.73681 24.5 11.3267 24.5 13C24.5 14.6733 24.1426 16.2632 23.5 17.6974V17H18.8083ZM0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13C25.5 19.9036 19.9036 25.5 13 25.5C6.09644 25.5 0.5 19.9036 0.5 13Z"
			/>
		</svg>
	);
};
