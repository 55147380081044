import React from "react";
import cloud01 from "~/assets/img/newLanding/cloud01.png";
import cloud02 from "~/assets/img/newLanding/cloud02.png";
import mountain from "~/assets/img/newLanding/mountain-img.png";
import cloud03 from "~/assets/img/newLanding/cloud03.png";
import cloud04 from "~/assets/img/newLanding/cloud04.png";
import discoverLogo from "~/assets/img/newLanding/logo-discord.png";
import logo from "~/assets/img/newLanding/logo.png";
import {
	FooterFacebookIcon,
	FooterTwitterIcon,
	FooterYoutubeIcon,
	LinkedinIcon,
	PersonaLogo,
} from "~/assets/svg/LandingIcon";
import { MsgIcon } from "~/assets/svg/InterfacesIcon";
import { Link } from "react-router-dom";
import useAlert from "~/common/hooks/useAlert";
import { Trans, useTranslation } from "react-i18next";

const LandingFooter = () => {
	const { t } = useTranslation();
	const contactInfo = [
		{
			icon: <LinkedinIcon />,
		},
	];
	const menuItems = [
		{
			title: t("Landing.LandingFooter.nav.menu.company.title"),
			menuItems: [
				t("Landing.LandingFooter.nav.menu.company.why-us"),
				t("Landing.LandingFooter.nav.menu.company.pricing"),
			],
		},
		{
			title: t("Landing.LandingFooter.nav.menu.services.title"),
			menuItems: [
				t("Landing.LandingFooter.nav.menu.services.hr-management"),
				t("Landing.LandingFooter.nav.menu.services.recruitment"),
				t("Landing.LandingFooter.nav.menu.services.it-management"),
				t("Landing.LandingFooter.nav.menu.services.eor"),
				t("Landing.LandingFooter.nav.menu.services.job-platform"),
			],
		},
		{
			title: t("Landing.LandingFooter.nav.menu.support.title"),
			menuItems: [
				t("Landing.LandingFooter.nav.menu.support.contact"),
				t("Landing.LandingFooter.nav.menu.support.resources"),
			],
		},
	];
	// const [showAlert, Alert] = useAlert();

	return (
		<>
			<footer className="w-full bg-[#DEEDF0] sm:pt-16 pt-8 max-w-[1920px] m-auto">
				<div className="max-w-[1920px] m-auto w-[95%] sm:w-[85%]">
					<div className="sm:flex block">
						<div className="lg:w-[40%] w-full sm:block flex justify-between items-center">
							<PersonaLogo />
							<div className="flex gap-3 mt-8">
								{contactInfo.map((item, index) => (
									<div className="" key={index}>
										<div>{item.icon}</div>
									</div>
								))}
							</div>
						</div>
						<div className="sm:gap-32 lg:flex pb-8 grid grid-cols-2 gap-4 sm:mt-0 mt-4">
							{menuItems.map((item, index) => (
								<div key={index} className="text-[20px] font-bold">
									{item.title.toUpperCase()}
									<div>
										{item.menuItems.map((item, index) => (
											<div
												key={index}
												className="text-[16px] font-normal my-4 text-grey-400 cursor-pointer"
											>
												<div>{item}</div>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
					<hr className="border-gray-300" />
					<div className="text-center pb-8 pt-4">
						{t("Landing.LandingFooter.nav.menu.copyright")}
					</div>
				</div>
			</footer>
		</>
	);
};

export default LandingFooter;

// *OLD CODE*

// import React from "react";
// import cloud01 from "~/assets/img/newLanding/cloud01.png";
// import cloud02 from "~/assets/img/newLanding/cloud02.png";
// import mountain from "~/assets/img/newLanding/mountain-img.png";
// import cloud03 from "~/assets/img/newLanding/cloud03.png";
// import cloud04 from "~/assets/img/newLanding/cloud04.png";
// import discoverLogo from "~/assets/img/newLanding/logo-discord.png";
// import logo from "~/assets/img/newLanding/logo.png";
// import { FooterFacebookIcon, FooterTwitterIcon, FooterYoutubeIcon } from "~/assets/svg/LandingIcon";
// import { MsgIcon } from "~/assets/svg/InterfacesIcon";
// import { Link } from "react-router-dom";
// import useAlert from "~/common/hooks/useAlert";
// import { Trans, useTranslation } from "react-i18next";
// import ButtonWave from "~/common/components/ButtonWave";

// const LandingFooter = () => {
// 	const { t } = useTranslation();
// 	const [showAlert, Alert] = useAlert();

// 	return (
// 		<>
// 			<footer className="landing-footer">
// 				<aside className="subscribe">
// 					<div className="img-box">
// 						<div className="img-subscribe">
// 							<img
// 								src={cloud01}
// 								alt={t("Landing.LandingFooter.img-alt.1")}
// 								className="img-cloud cloud01"
// 							/>
// 							<img
// 								src={cloud02}
// 								alt={t("Landing.LandingFooter.img-alt.1")}
// 								className="img-cloud cloud02"
// 							/>
// 							<img
// 								src={mountain}
// 								alt={t("Landing.LandingFooter.img-alt.2")}
// 								className="mountain-img"
// 							/>
// 							<img
// 								src={cloud03}
// 								alt={t("Landing.LandingFooter.img-alt.1")}
// 								className="img-cloud cloud03"
// 							/>
// 							<img
// 								src={cloud04}
// 								alt={t("Landing.LandingFooter.img-alt.1")}
// 								className="img-cloud cloud04"
// 							/>
// 						</div>
// 					</div>
// 					<div className="subscribe-content">
// 						<dl>
// 							<dt>Contact Us</dt>
// 							<dd>cs@boomco.org</dd>
// 						</dl>
// 						{/*<dl>*/}
// 						{/*	<dt>Family site</dt>*/}
// 						{/*	<dd>https://effectmall.com</dd>*/}
// 						{/*	<dd>https://techstorm.io</dd>*/}
// 						{/*</dl>*/}
// 						{/*<div className="apply-wrap">*/}
// 						{/*	<div className="img-wrap">*/}
// 						{/*		<img*/}
// 						{/*			src={discoverLogo}*/}
// 						{/*			alt={t("Landing.LandingFooter.img-alt.3")}*/}
// 						{/*		/>*/}
// 						{/*	</div>*/}
// 						{/*	/!*<button*!/*/}
// 						{/*	/!*	type="button"*!/*/}
// 						{/*	/!*	className="btn__solid text-up angled"*!/*/}
// 						{/*	/!*	color="black"*!/*/}
// 						{/*	/!*	onClick={showAlert}*!/*/}
// 						{/*	/!*>*!/*/}
// 						{/*	/!*	<span>{t("Landing.LandingFooter.button.1")}</span>*!/*/}
// 						{/*	/!*	<span>{t("Landing.LandingFooter.button.2")}</span>*!/*/}
// 						{/*	/!*	<span>{t("Landing.LandingFooter.button.3")}</span>*!/*/}
// 						{/*	/!*	<span>{t("Landing.LandingFooter.button.4")}</span>*!/*/}
// 						{/*	/!*	<span />*!/*/}
// 						{/*	/!*</button>*!/*/}
// 						{/*	<ButtonWave*/}
// 						{/*		className={"btn__solid angled"}*/}
// 						{/*		onClick={showAlert}*/}
// 						{/*		text={t("Landing.LandingFooter.join")}*/}
// 						{/*		color="black"*/}
// 						{/*	/>*/}
// 						{/*</div>*/}
// 					</div>
// 				</aside>
// 				<div className="footer-top">
// 					<div className="wrap">
// 						<div className="img-wrap">
// 							<img src={logo} alt={t("Landing.LandingFooter.img-alt.3")} />
// 						</div>
// 						<ul className="nav">
// 							<ul className="nav-list">
// 								<li className="nav-item disabled">
// 									{t("Landing.LandingFooter.nav.1")}
// 								</li>
// 								<li className="nav-item disabled">
// 									{t("Landing.LandingFooter.nav.2")}
// 								</li>
// 								<li className="nav-item disabled">
// 									{t("Landing.LandingFooter.nav.3")}
// 								</li>
// 								<li className="nav-item disabled">
// 									{t("Landing.LandingFooter.nav.4")}
// 								</li>
// 							</ul>
// 							<ul className="sns-list">
// 								<li className="nav-item ico-facebook disabled">
// 									<FooterFacebookIcon />
// 								</li>
// 								<li className="nav-item ico-youtube disabled">
// 									<FooterYoutubeIcon />
// 								</li>
// 								<li className="nav-item ico-twitter disabled">
// 									<FooterTwitterIcon />
// 								</li>
// 								<li className="nav-item ico-msg disabled">
// 									<MsgIcon />
// 								</li>
// 							</ul>
// 						</ul>
// 					</div>
// 				</div>
// 				<div className="footer-bottom">
// 					<div className="wrap">
// 						<ul className="footer-content">
// 							<li className="address">
// 								<b>(주)붐코커뮤니케이션</b> 서울특별시 마포구 성미산로 177 (연남동,
// 								투희빌딩)
// 								{/* <Trans
// 									i18nKey="Landing.LandingFooter.footerContent.address"
// 									components={[<b />]}
// 								/> */}
// 							</li>
// 							<li className="num">
// 								<span>사업자등록번호:494-86-01834</span>
// 								<span>통신판매업:2022-서울마포-0684</span>
// 								<span>대표자:박경훈</span>
// 								{/* <span>{t("Landing.LandingFooter.footerContent.num.1")}</span> */}
// 								{/* <span>{t("Landing.LandingFooter.footerContent.num.2")}</span> */}
// 								{/* <span>{t("Landing.LandingFooter.footerContent.num.3")}</span> */}
// 							</li>
// 							<li className="btn-group">
// 								<Link to={"/policy/terms"} target="_blank" className="btn-policy">
// 									{t("Landing.LandingFooter.footerContent.btn-policy")}
// 								</Link>
// 								<Link to={"/policy/privacy"} target="_blank" className="btn-policy">
// 									{t("Landing.LandingFooter.footerContent.btn-personal")}
// 								</Link>
// 							</li>
// 							<li className="copyright">
// 								Copyright 2020 by Boom Co ., Ltd All rights reserved.
// 							</li>
// 						</ul>
// 						<div className="btn-group">
// 							<Link to={"/policy/terms"} target="_blank" className="btn-policy">
// 								{t("Landing.LandingFooter.footerContent.btn-policy")}
// 							</Link>
// 							<Link to={"/policy/privacy"} target="_blank" className="btn-policy">
// 								{t("Landing.LandingFooter.footerContent.btn-personal")}
// 							</Link>
// 						</div>
// 					</div>
// 				</div>
// 			</footer>
// 			{/*준비중 alert 모달*/}
// 			<Alert
// 				message={t("Landing.LandingFooter.footerContent.Alert")}
// 				confirm={t("button.check")}
// 				type="info"
// 			/>
// 		</>
// 	);
// };

// export default LandingFooter;
