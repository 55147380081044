const style = () => ({
	absoluteLineBlock: {
		position: "absolute",
		top: 0,
	},
	lineBlock: {
		display: "inline-block",
		flex: "0 0 auto",
		height: "100%",
		position: "relative",
	},
	lineChildren: {
		display: "inline-block",
		height: "100%",
	},
	nodeContent: {
		height: "100%",
		"& $lineBlock, & $absoluteLineBlock": {
			display: "inline-block",
			flex: "0 0 auto",
			height: "100%",
			position: "relative",
		},
		"& $rowContents": {
			alignItems: "center",
			backgroundColor: "#f8f8f8",
			border: "1px solid #e3e4e5",
			borderRadius: "4px",
			color: "#595959",
			display: "flex",
			height: "100%",
			justifyContent: "space-between",
			padding: 10,
			position: "relative",
		},
		"& $rowLabel": {
			flex: 1,
			padding: "0px 7px",
			"& *": {
				fontSize: "15px !important",
				fontWeight: "600 !important",
				color: "#878296",
			},
		},
	},
	row: {
		"& $rowIcon, & $rowLabel": {
			alignItems: "center",
			display: "flex",
		},
		"&.hiddenButtons": {
			"& $rowToolbar": {
				display: "none",
			},
			"&:hover": {
				"& $rowToolbar": {
					display: "flex",
				},
			},
			"&$onDrag": {
				"& $rowToolbar": {
					display: "none !important",
				},
			},
		},

		flex: 1,
		height: "100%",
		// transition: "all 0 ease-in-out",
		position: "relative",
		// whiteSpace: 'nowrap'
	},

	draggedDescendant: {
		"& $rowContents": {
			opacity: "0.6",
			// border: "1px solid #00a4ff !importatm",
		},
	},
	rowCancelPad: {
		"& $rowContents": {
			opacity: "0.4 !important",
			backgroundColor: "#fcadb4 !important",
			// border: "none",
			color: "#f51b2e !important",
			border: "2px solid #f51b2e !important",
		},
		"& *": {
			color: "#f51b2e !important",
		},
	},
	rowLandingPad: {
		"& $rowContents": {
			opacity: ".6",
			// backgroundColor: "#00a4ff",
			// color: "#00a4ff",
			// border: "none",
			// border: "2px solid #00a4ff",
		},
	},
	rowContents: {},
	isSelected: {
		"& $rowContents": {
			opacity: ".5",
			backgroundColor: "#90d7ff !important",
			border: "2px solid #00a4ff !important",

			"& span": {
				color: "#00283c !important",
			},
		},
		"& .org-number": {
			backgroundColor: "#2a95c7 !important",
			color: "#f8f8f8 !important",
		},
	},
	rowIcon: {
		color: "#D9D9D9",
	},
	rowLabel: {},
	rowSearchFocus: {
		boxShadow: "inset 0 -7px 7px -3px #fc6421",
	},
	rowSearchMatch: {
		boxShadow: "inset 0 -7px 7px -3px #0080ff",
	},
	rowToolbar: {},
	onDrag: {},
	rowWrapper: {
		// opacity가 1일때 drag ghost에 배경이 나타나는 버그가 있어서 0.999로 설정
		"&:active": {
			opacity: 0.999,
			cursor: "grabbings",
		},
		"&:hover": {
			opacity: 0.999,
		},
		boxSizing: "border-box",
		cursor: "grab",
		display: "flex",
		height: "100%",
		margin: "3px 0",
	},
	rowWrapperDragDisabled: {
		cursor: "pointer",
	},
	isLandingPadActive: {},
});

export default style;
